import { CurrencyPipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { DoBootstrap, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { BgzvComponentsModule } from '@components/components.module';
import { KFThemeCommonModule } from '@de.fiduciagad.kundenportal/kf-theme/common';
import {
  ENVIRONMENT_SPECIFIC_CUSTOM_ELEMENT,
  ENVIRONMENT_SPECIFIC_IMPORTS,
  ENVIRONMENT_SPECIFIC_PROVIDERS,
} from '@environment/environment';
import { BgzvMarginalsModule } from '@marginals/marginals.module';
import { AuthInterceptor } from '@modules/auth';
import { BgzvOverlayModule } from '@overlays/overlay.module';
import { BgzvScreensModule } from '@screens/screens.module';
import { BgzvServicesModule } from '@services/services.module';
import { USERBACK } from '@services/userback/userback';
import { CustomDateAdapter } from '@utils/customDateAdapter';
import { ErrorInterceptor } from '@utils/error.interceptor';
import { BgzvUtilsModule } from '@utils/utils.module';
import { BgzvLibraryModule } from 'bgzv-frontend-library';
import { KfThemeModule } from './kf-theme_module';
import { MaterialModule } from './material_module';

/* PORTAL CONFIG */
// eslint-disable-next-line @typescript-eslint/naming-convention
declare const Zone: any;

registerLocaleData(localeDe, 'de-DE');

@NgModule({
  declarations: [AppComponent],
  imports: [
    ...ENVIRONMENT_SPECIFIC_IMPORTS,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    MaterialModule,
    BgzvComponentsModule,
    BgzvServicesModule,
    BgzvUtilsModule,
    BgzvMarginalsModule,
    BgzvOverlayModule,
    BgzvScreensModule,
    BgzvLibraryModule,
    KFThemeCommonModule,
    KfThemeModule,
  ],
  providers: [
    ...ENVIRONMENT_SPECIFIC_PROVIDERS,
    CurrencyPipe,
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    { provide: USERBACK, useFactory: () => (window as any).Userback },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
})
export class AppModule implements DoBootstrap {
  constructor(private readonly injector: Injector) {}

  ngDoBootstrap(): void {
    const app = ENVIRONMENT_SPECIFIC_CUSTOM_ELEMENT(AppComponent, this.injector);
    if (!customElements.get('bgzv-frontend-prelim')) {
      customElements.define('bgzv-frontend-prelim', app);
    }
  }
}
