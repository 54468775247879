import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { environment } from '@environment/environment';
import { AuthService } from '@modules/auth';
import { ActionService } from '@services/action-service/action.service';
import { ConfigService } from '@services/config-service/config.service';
import { ContextService } from '@services/context-service/context.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private authService: AuthService,
    private configService: ConfigService,
    private contextService: ContextService,
    private actionService: ActionService,
    private snack: MatSnackBar
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map(result => {
        // console.log(result);
        return result;
      }),
      catchError((err: HttpErrorResponse, caught): Observable<HttpEvent<any>> => {
        if (err.status >= 300) {
          if (this.configService.isDebugMode) {
            const config = new MatSnackBarConfig();
            config.duration = 5000;

            this.snack.open(err.statusText, 'Code: ' + err.status, config);
          }
          return this.handleError(err, request);
        }
        return of(null);
      })
    );
  }

  private handleError(error: HttpErrorResponse, request: HttpRequest<any>) {
    // ignore the ping-service
    if (environment.platform === 'aws' && error.url.includes('ping')) {
      return throwError('No Internet detected');
    }

    if (environment.platform === 'aws' && (error.status === 401 || error.status === 403)) {
      // close any overlays
      if (this.contextService.currentOverlayContext !== null) {
        this.actionService.setAction({ target: 'overlay-main', action: 'close-logout' });
      } else {
        this.router.navigate(['login'], { state: { reason: 'unauthorized' } });
      }

      if (this.authService.authState === 'signedIn') {
        this.authService.logout();
      }

      return throwError('Unauthorized access (session expired)');
    } else {
      return throwError(error);
    }
  }
}
