import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { color, libIcons } from 'bgzv-frontend-library';

export interface DialogConfirmData {
  questionHeading?: string;
  questionText: string;
  confirmText: string;
  denyText?: string;
  target?: any;
  context?: string;
  type?: string;
  showClose?: boolean;
  showWarningIcon?: boolean;
}

@Component({
  selector: 'app-dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  styleUrls: ['./dialog-confirm.component.scss'],
})
export class DialogConfirmComponent implements OnInit {
  readonly color = color;
  readonly buttonIcon = libIcons;

  constructor(
    public dialogRef: MatDialogRef<DialogConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogConfirmData
  ) {}

  ngOnInit(): void {}

  onSelect(confirmed: boolean = false) {
    this.dialogRef.close({ confirmed });
  }

  public onClose() {
    this.dialogRef.close();
  }
}
