import { HashLocationStrategy, PlatformLocation } from '@angular/common';
import { Injectable } from '@angular/core';
import { INavigationService } from '@de.fiduciagad.kundenportal/ui-context';
import { PortalContextProvider } from './services/portal-context.provider';

@Injectable()
export class CustomLocationStrategy extends HashLocationStrategy {
  private navigationService: INavigationService;

  constructor(_platformLocation: PlatformLocation, portalContextProvider: PortalContextProvider) {
    super(_platformLocation);
    this.navigationService =
      (portalContextProvider.portalContext?.getNavigationService() as INavigationService) || null;
  }

  override getBaseHref(): string {
    // const basePath = this.navigationService?.getPortalBasePath() || null;
    // return basePath ? `${basePath}webcomp/bgzv-frontend-prelim` : '';
    return window.location.pathname;
  }

  override prepareExternalUrl(internal: string): string {
    return this.getBaseHref() + '#' + internal;
  }
}
