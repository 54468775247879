import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigurationResponse } from '@domain/app/configuration.domain';
import { DocumentsResponseItem } from '@domain/app/documents.domain';
import { HubDocumentUploadResponse } from '@domain/app/media.domain';
import { ConsultantResponseItem } from '@domain/hub/consultant.domain';
import { GetCustomerConsultationResponseItem, GetHubConsultationResponseItem } from '@domain/hub/consultation.domain';
import { CustomerRequestItem, CustomerResponseItem } from '@domain/hub/customer.domain';
import { DataFieldGroupItem } from '@domain/hub/datafield.domain';
import { UNDTicketResponse, UNDUploadRequest, UNDUploadResponse } from '@domain/hub/undDomain';
import { environment } from '@environment/environment';
import { CacheService } from '@services/cache-service/cache.service';
import { Observable } from 'rxjs';

export class QueryHub {
  protected _headers = new HttpHeaders().set('Content-Type', 'application/json');
  protected options = { headers: this._headers };

  public api = environment.api || 'api/v1';
  public hub = environment.hub || 'hub/v1';

  // VP Portal URL
  private portalUrl = window.location.origin;
  private proxyGatewayPath = 'proxy-gateway/serviceproxy';
  private portalServiceUrl = 'services_cloud/portal';
  private portalUrlBase = `/${this.portalServiceUrl}/${this.proxyGatewayPath}`;

  constructor(
    protected http: HttpClient,
    protected cacheService: CacheService
  ) {
    if (environment.platform === 'vp') {
      this.hub = `${this.portalUrlBase}/bgzv-backend-guided-service-hub/api/v1`;
      this.api = `${this.portalUrlBase}/bgzv-backend-consultation-app/api/v1`;
    }
  }

  // -------------------------------------------------------------------------------------- //
  // ---------------------------------- APP GET METHODS ----------------------------------- //
  // -------------------------------------------------------------------------------------- //
  getHubConfiguration(): Observable<ConfigurationResponse> {
    return this.http.get<ConfigurationResponse>(`${this.hub}/configuration`);
  }

  getAllCustomers(userId: string = undefined): Observable<CustomerResponseItem[]> {
    const query = userId ? `?userId=${userId}` : '';
    return this.http.get<CustomerResponseItem[]>(`${this.hub}/customers${query}`);
  }

  getConsultants(): Observable<ConsultantResponseItem[]> {
    return this.http.get<ConsultantResponseItem[]>(`${this.hub}/consultants`);
  }

  getCustomerById(customerId: string): Observable<CustomerResponseItem> {
    return this.http.get<CustomerResponseItem>(`${this.hub}/customers/${customerId}`);
  }

  getCustomerByName(name: string): Observable<CustomerResponseItem[]> {
    const query = `?corporationName=${name}`;
    return this.http.get<CustomerResponseItem[]>(`${this.hub}/customers/by-name${query}`);
  }

  getCustomerByBankId(bankId: string): Observable<CustomerResponseItem[]> {
    const query = `?customerIdBank=${bankId}`;
    return this.http.get<CustomerResponseItem[]>(`${this.hub}/customers/by-bank-id${query}`);
  }

  getDataFieldsGroups(): Observable<DataFieldGroupItem[]> {
    return this.http.get<DataFieldGroupItem[]>(`${this.hub}/data-field-groups`);
  }

  getConsultationsByCustomerId(customerId: string): Observable<GetCustomerConsultationResponseItem[]> {
    return this.http.get<GetCustomerConsultationResponseItem[]>(`${this.hub}/consultations/customers/${customerId}`);
  }

  getConsultationById(consultationId: string): Observable<GetHubConsultationResponseItem> {
    return this.http.get<GetHubConsultationResponseItem>(`${this.hub}/consultations/${consultationId}`);
  }

  getPreliminaries(): Observable<GetCustomerConsultationResponseItem[]> {
    return this.http.get<GetCustomerConsultationResponseItem[]>(`${this.hub}/consultations/preliminaries`);
  }

  getBankBankHubDocument(gshId: string, customerId: string): Observable<any> {
    return this.http.get<DocumentsResponseItem[]>(`${this.hub}/customers/${customerId}/documents/${gshId}/download`, {
      responseType: 'blob' as 'json',
    });
  }

  // -------------------------------------------------------------------------------------- //
  // ---------------------------------- APP PUT METHODS ----------------------------------- //
  // -------------------------------------------------------------------------------------- //
  putCustomerById(customerId: string, data: CustomerRequestItem): Observable<any> {
    const sendData = JSON.stringify(data);
    return this.http.put<any>(`${this.hub}/customers/${customerId}`, sendData, this.options);
  }

  // -------------------------------------------------------------------------------------- //
  // --------------------------------- APP DELETE METHODS --------------------------------- //
  // -------------------------------------------------------------------------------------- //
  deleteCustomerById(customerId: string): Observable<any> {
    return this.http.delete<any>(`${this.hub}/customers/${customerId}`);
  }

  deleteConsultationViaBankHub(consultationId: string): Observable<any> {
    return this.http.delete<any>(`${this.hub}/consultations/${consultationId}`);
  }

  // -------------------------------------------------------------------------------------- //
  // ------------------------------- APP POST/PATCH METHODS ------------------------------- //
  // -------------------------------------------------------------------------------------- //
  postCustomer(data: CustomerRequestItem): Observable<CustomerResponseItem> {
    const sendData = JSON.stringify(data);
    return this.http.post<CustomerResponseItem>(`${this.hub}/customers`, sendData, this.options);
  }

  postCustomerToFavorite(customerId: string, userId: string): Observable<any> {
    const query = `?userId=${userId}`;
    return this.http.post<any>(`${this.hub}/customers/${customerId}/favourite${query}`, null, this.options);
  }

  patchCustomerFavoriteStatus(customerId: string, userId: string): Observable<any> {
    const query = `?userId=${userId}`;
    return this.http.patch<any>(
      `${this.hub}/customers/${customerId}/favourite/update-status${query}`,
      null,
      this.options
    );
  }

  postUploadDocument(customerId: string, file: File): Observable<HubDocumentUploadResponse> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<HubDocumentUploadResponse>(` ${this.hub}/customers/${customerId}/documents/upload`, formData);
  }

  // --------------------------------------------- //
  // ------------ UND SERVICE (VP ONLY) ---------- //
  // --------------------------------------------- //

  getUNDTicket(): Observable<UNDTicketResponse> {
    return this.http.get<UNDTicketResponse>(`${this.hub}/und-service/ticket`);
  }

  postUNDUploadFile(data: FormData): Observable<UNDUploadResponse> {
    return this.http.post<UNDUploadResponse>(`/${this.portalServiceUrl}/datasharing/ticket/upload?owner=bgzv`, data);
  }

  postUNDDataToBackend(data: UNDUploadResponse, customerId: string): Observable<HubDocumentUploadResponse> {
    const sendData = {
      undVersionId: data.undID,
      fileName: data.fileName,
    } as UNDUploadRequest;
    return this.http.post<any>(`${this.hub}/customers/${customerId}/documents/upload/und`, sendData, this.options);
  }
}
