<div class="d-flex flex-column">
  @if (consultationDocuments && consultationDocuments.length === 0) {
    <h3 class="mb-4 vr-text-regular font-weight-bold">Ihre Dokumente hinzufügen:</h3>
  }

  @if (consultationDocuments && consultationDocuments.length !== 0) {
    <h3 class="mb-4 vr-text-regular font-weight-bold">Ihre Dokumente</h3>
    <ul class="list-unstyled document-list">
      @for (document of consultationDocuments; track document; let i = $index) {
        <li>
          <article class="w-100 d-flex align-items-center">
            <p class="mb-0 d-flex align-items-center">
              <vr-icon
                [icon]="
                  document.filename.search('.pdf') !== -1
                    ? 'vr-icon-document-pdf'
                    : 'vr-icon-document-picture' || 'vr-icon-document'
                "
                [iconColor]="color.find(color.name.InteractionPrimary)"
                aria-hidden="true"
              ></vr-icon>
              {{ document.filename }}
            </p>
            <button
              mat-icon-button
              class="align-self-end mr-0 ml-auto"
              color="primary"
              (click)="onDeleteClick($event, i)"
              title="Datei '{{ document.filename }}'' löschen?"
            >
              <kf-icon name="ic_loeschen_24" />
            </button>
          </article>
        </li>
      }
    </ul>
  }
  <documents-upload></documents-upload>
</div>
