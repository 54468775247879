import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ActionService {
  public _actionSubject: Action = null;
  public _actionSource = new BehaviorSubject<Action>(this._actionSubject);
  public action = this._actionSource.asObservable();

  constructor() {}

  setAction(action: Action) {
    // optional promise - for actions after the action
    const promise = new Promise<any>((resolve, reject) => {
      this._actionSource.next(action);
      this._actionSource.next({ target: '', action: '' });
      resolve(null);
    });
    return promise;
  }
}

export interface Action {
  target: string;
  action: string;
  options?: any;
}
