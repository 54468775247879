<!-- SLIDER & CONNECTEDSLIDER -->
<form [formGroup]="sliderFormGroup">
  @if (this.question.type === this.questionEnum.slider || this.question.type === this.questionEnum.connectedSlider) {
    <mat-form-field>
      @if (question?.type === questionEnum?.connectedSlider) {
        <mat-label>{{ this.question.answers[0].label }}</mat-label>
      } @else {
        <mat-label>Ihre Eingabe</mat-label>
      }
      <input
        #sliderInput
        inputmode="numeric"
        formControlName="text"
        [attr.data-testcafe]="'slider-description-' + testcafeLabel"
        [pattern]="numRegex"
        (change)="textToSlider(); sendAnswerSlider()"
        matInput
      />
      @if (hasAnswerDesc) {
        <mat-hint class="info" [ngClass]="{ 'info--hidden': !getCurrentAnswer()?.tooltip }">
          <ng-template
            *ngTemplateOutlet="infoIcon; context: { description: getCurrentAnswer()?.tooltip }"
          ></ng-template>
        </mat-hint>
      }
      <mat-error>
        @if (sliderFormGroup.get('text').invalid) {
          <kf-input-error [message]="getNumInputError()" />
        }
      </mat-error>
    </mat-form-field>
    <div>{{ firstLabelDependent }}</div>
    <mat-slider #slider [min]="this.min" [max]="this.max" [step]="step" [showTickMarks]="showTickMarks">
      <input
        matSliderThumb
        formControlName="slider"
        (input)="sliderToText({ parent: slider, source: sliderThumb, value: sliderThumb.value }, false)"
        (change)="sendAnswerSlider()"
        #sliderThumb="matSliderThumb"
      />
    </mat-slider>
    @if (showTickMarks && question?.type === questionEnum?.slider) {
      <ul class="d-flex list-unstyled slider-label-container">
        @for (label of this.labelArray; track label; let index = $index) {
          <li class="slider-label slider-label--linear" [style]="getLabelPositionsLinear(index)">
            {{ label.toLocaleString() }}
          </li>
        }
      </ul>
    } @else {
      <ul class="d-flex justify-content-between list-unstyled slider-label-container">
        <li class="text-center slider-label">{{ this.min.toLocaleString() }}</li>
        <li class="text-center slider-label">{{ this.max.toLocaleString() }}</li>
      </ul>
    }
    @if (this.question.type === this.questionEnum.connectedSlider) {
      <!-- CONNECTEDSLIDER -->
      <div class="mt-5">{{ secondLabelDependent }}</div>
      <mat-slider #connectedSlider [min]="this.min" [max]="this.max" [step]="step">
        <input
          matSliderThumb
          formControlName="connectedSlider"
          (input)="
            sliderToText(
              { parent: connectedSlider, source: connectedSliderThumb, value: connectedSliderThumb.value },
              true
            )
          "
          (change)="sendAnswerSlider()"
          #connectedSliderThumb="matSliderThumb"
        />
      </mat-slider>
      <ul class="d-flex justify-content-between list-unstyled slider-label-container">
        <li class="text-center slider-label">{{ this.min.toLocaleString() }}</li>
        <li class="text-center slider-label">{{ this.max.toLocaleString() }}</li>
      </ul>
    }
  } @else if (this.question.type === this.questionEnum.expSlider) {
    <!-- DECLOGSLIDER -->
    <mat-form-field>
      <mat-label>Ihre Eingabe</mat-label>
      <input
        #expSliderInput
        inputmode="numeric"
        formControlName="expText"
        [attr.data-testcafe]="'slider-description-' + testcafeLabel"
        [pattern]="numRegex"
        (change)="expTextToSlider(); sendAnswerSlider()"
        matInput
      />
      @if (hasAnswerDesc) {
        <mat-hint class="info" [ngClass]="{ 'info--hidden': !getCurrentAnswer()?.tooltip }">
          <ng-template
            *ngTemplateOutlet="infoIcon; context: { description: getCurrentAnswer()?.tooltip }"
          ></ng-template>
        </mat-hint>
      }
      <mat-error>
        @if (sliderFormGroup.get('expText').invalid) {
          <kf-input-error [message]="getNumInputError()" />
        }
      </mat-error>
    </mat-form-field>
    <mat-slider
      #expSlider
      id="expSlider"
      [min]="this.sliderPropertiesMap.get(question.id).expSliderMin"
      [max]="this.sliderPropertiesMap.get(question.id).expSliderMax"
      [step]="step"
    >
      <input
        matSliderThumb
        formControlName="expSlider"
        (input)="expSliderToText({ parent: expSlider, source: expSliderThumb, value: expSliderThumb.value })"
        (change)="sendAnswerSlider()"
        #expSliderThumb="matSliderThumb"
      />
    </mat-slider>
    <div class="d-flex justify-content-between tick-mark-container">
      @for (mark of this.labelArray; track mark; let index = $index) {
        @if (min === 0 && index === 0) {
          <!--push following marks by 1 step-width for 0-->
          <div class="tick-mark" [style]="getMargin(index)"></div>
        } @else if (maxNotPowTen && index === this.labelArray.length - 1) {
          <div class="tick-mark" [style]="getMargin(index)"></div>
        } @else {
          <div class="tick-mark"></div>
        }
      }
    </div>
    <ul class="d-flex list-unstyled slider-label-container">
      @for (label of this.labelArray; track label; let index = $index) {
        @if (index === 0) {
          <li id="label-{{ index }}" class="slider-label slider-label--exp-first" [style]="getLabelPositionsExp(index)">
            {{ label.toLocaleString() }}
          </li>
        } @else if (index === this.labelArray.length - 1) {
          <li id="label-{{ index }}" class="slider-label slider-label--exp-last" [style]="getLabelPositionsExp(index)">
            {{ label.toLocaleString() }}
          </li>
        } @else {
          <li id="label-{{ index }}" class="slider-label slider-label--exp" [style]="getLabelPositionsExp(index)">
            {{ label.toLocaleString() }}
          </li>
        }
      }
    </ul>
  }
  @if (this.hasTextField) {
    <mat-form-field>
      <mat-label>Notizen</mat-label>
      <textarea
        #sliderNote
        [attr.data-testcafe]="'slider-note-' + this.testcafeLabel"
        formControlName="note"
        cdkTextareaAutosize
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="4"
        (change)="sendAnswerSlider()"
        matInput
      ></textarea>
    </mat-form-field>
  }
</form>

<ng-template #infoIcon let-description="description">
  <kf-icon
    [kfTooltip]="description"
    [attr.data-testcafe]="'slider-infoIcon-' + this.testcafeLabel"
    alt="Details"
    class="info--icon"
    kfTooltipPosition="after"
    kfTooltipShow="hover"
    [kfTooltipDisabled]="isTouchDevice"
    name="ic_infozeichen-mit-kreis_24"
    role="button"
    tabindex="0"
    (click)="showInfo = !isTouchDevice ? false : !showInfo"
  ></kf-icon>
  @if (showInfo) {
    <span>{{ description }}</span>
  }
</ng-template>
