import { Injectable, OnDestroy } from '@angular/core';
import { ConfigBankInfoItem, ConfigurationResponse } from '@domain/app/configuration.domain';
import { AuthService } from '@modules/auth';
import { ClientService } from '@services/client-service/client.service';
import { QueryService } from '@services/query-service/query.service';
import { ReplaySubject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConfigService implements OnDestroy {
  private _config: ConfigurationResponse;
  private _authSub: Subscription;
  private _instSub: Subscription;

  public newConfig = new ReplaySubject<void>();

  constructor(
    private queryService: QueryService,
    private auth: AuthService,
    private clientService: ClientService
  ) {
    if (this.auth.authState === 'signedIn' && this.clientService.instanceId) {
      this.queryService.getConfiguration().subscribe(data => {
        this.config = data;
      });
    }

    this._authSub = this.auth.currentAuthState.subscribe(newAuthState => {
      if (newAuthState === 'signedIn' && this.clientService.instanceId) {
        this.queryService.getConfiguration().subscribe(data => {
          this.config = data;
        });
      }
    });
    this._instSub = this.clientService.instanceIdChanged.subscribe(newInstanceId => {
      if (this.auth.authState === 'signedIn' && newInstanceId) {
        this.queryService.getConfiguration().subscribe(data => {
          this.config = data;
          this.newConfig.next();
        });
      }
    });
  }

  ngOnDestroy(): void {
    this._authSub?.unsubscribe();
    this._instSub?.unsubscribe();
  }

  public getSettingsValue(key): string {
    const result = this.config.settings.find(x => x.key === key);
    if (result) {
      return result.value || null;
    }
    return null;
  }
  public getSettingsDefaultValue(key) {
    const result = this.config.settings.find(x => x.key === key);
    if (result) {
      return result.defaultValue || null;
    }
    return null;
  }

  public get config(): ConfigurationResponse {
    return this._config;
  }
  public set config(value: ConfigurationResponse) {
    this._config = value;
  }

  get isDebugMode(): boolean {
    if (!this.config) {
      return false;
    }
    const setting = this.config.settings.find(x => x.key === 'debugModeFrontend');
    return setting ? setting.value === '1' : false;
  }

  get bankInfo(): ConfigBankInfoItem[] {
    if (!this.config || !this.config.bankInformation) {
      return [];
    }
    return this.config.bankInformation;
  }
  get settings(): ConfigBankInfoItem[] {
    if (!this.config || !this.config.settings) {
      return [];
    }
    return this.config.settings;
  }
}
