import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessagesRequestItem, MessagesResponseItem } from '@domain/app/messages.domain';
import { GivenAnswerEnum } from '@enums';

import { ClientService } from '@services/client-service/client.service';
import { QueryService } from '@services/query-service/query.service';

import { color, libIcons } from 'bgzv-frontend-library';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit {
  @Input() chatContext: string;

  private cmp = (a: MessagesResponseItem, b: MessagesResponseItem) => {
    const newer = moment(a.createdAt).isAfter(b.createdAt);
    const res = newer ? +1 : newer === false ? -1 : 0;

    return res !== 0 ? res : a.createdAt > b.createdAt ? +1 : a.createdAt < b.createdAt ? -1 : 0;
  };

  public message;
  public messages: MessagesResponseItem[];
  public messageData: MessagesResponseItem[] = [];
  public messageSending: boolean = false;
  public messagesLoading: boolean = true;

  public readonly buttonIcon = libIcons;
  public readonly color = color;
  public shouldReload: boolean = false;

  public GivenAnswerEnum = GivenAnswerEnum;
  public isAtBottom: boolean = true;
  public error: boolean = false;
  public loadingMessages: boolean = false;
  public loading: boolean = false;

  readonly moment = moment.locale('de');

  @ViewChild('chatFrame') chatFrame: ElementRef;

  constructor(
    private clientService: ClientService,
    private queryService: QueryService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getMessageData();
  }

  public onChatScroll(): void {
    const obj = this.chatFrame?.nativeElement;
    this.isAtBottom = obj ? obj.scrollHeight - obj.offsetHeight - obj.scrollTop < 1 : true;
  }

  public onMoreClick() {
    this.messages = [...this.fillCurrentMessages(this.messages.length)];
    this.isAtBottom = false;
  }

  public isCustomer(messageSender: string): boolean {
    return messageSender === this.GivenAnswerEnum.customer;
  }

  public timestamp(createdAt: string, output: string = null): string {
    if (output && output === 'when') {
      return moment(new Date(createdAt)).fromNow();
    } else {
      return moment(new Date(createdAt)).format('HH:mm');
    }
  }

  public testcafeLabel(createdAt: string, message: string) {
    const time = moment(new Date(createdAt)).format('DD.MM.yyyy, HH:mm');
    return `chat-div-message-${time.replace(/ /g, '')}-${message.replace(/ /g, '').slice(0, 12)}`;
  }

  public scrollToBottom(): void {
    this.chatFrame?.nativeElement?.scroll({
      top: this.chatFrame?.nativeElement?.scrollHeight,
      behavior: 'smooth',
    });
  }

  public getMessageData(): void {
    this.loadingMessages = true;
    this.messageRequest &&
      this.messageRequest
        .pipe(
          finalize(() => {
            this.loadingMessages = false;
            setTimeout(() => this.scrollToBottom(), 500);
          })
        )
        .subscribe(data => {
          this.messageData = data.sort(this.cmp);
          this.messages = this.fillCurrentMessages(0);
          this.error = false;
        });
    this.messagesLoading = false;
  }

  public sendMessage() {
    if (this.isStringEmptyOrSpaces(this.message)) {
      return;
    }

    const sendData: MessagesRequestItem = {
      sender: GivenAnswerEnum.customer,
      text: this.message,
    };

    this.queryService
      .postMessage(this.clientService.consultationId, sendData)
      .pipe(
        finalize(() => {
          this.messageSending = false;
        })
      )
      .subscribe(
        resp => {
          const newMessage = resp;

          this.message = '';
          this.messageData = [...this.messageData, newMessage];
          this.messages = [...this.messages, newMessage];
          setTimeout(() => this.scrollToBottom(), 50);
        },
        error => {
          this.error = true;
          this.scrollToBottom();
        }
      );
  }

  public isStringEmptyOrSpaces(str) {
    return str === null || str?.match(/^\s*$/) !== null;
  }

  private fillCurrentMessages(index: number) {
    const arr = [...this.messageData];
    if (arr.length - index < 0 || arr.length - index - 5 < 0) {
      return this.messageData;
    }
    return arr.splice(arr.length - index - 5, arr.length - 1);
  }

  private get messageRequest(): Observable<MessagesResponseItem[]> {
    if (this.chatContext === 'consultation') {
      return this.queryService.getConsultationMessages(this.clientService.consultationId);
    }
    return of([]);
  }

  get moreToShow() {
    return this.messageData?.length > this.messages?.length;
  }
}
