<h3 class="sr-only">Nachrichten</h3>
@if (messages?.length !== 0) {
  <div #chatFrame (scroll)="onChatScroll()" class="position-relative h-100 d-flex flex-column chat-frame">
    <button
      mat-flat-button
      class="d-flex button-scroll button-scroll--up"
      [attr.data-testcafe]="'screenStart-button-moreToShow'"
      [class.hide]="!moreToShow"
      (click)="onMoreClick()"
    >
      <span>Vorherige Nachrichten</span>
    </button>

    @for (message of messages; track message; let i = $index) {
      @if (timestamp(messages[i].createdAt, 'when') !== timestamp(messages[i - 1]?.createdAt, 'when')) {
        <span class="w-100 d-block text-center vr-text-label font-weight-bold time-stamp">{{
          timestamp(message.createdAt, 'when')
        }}</span>
      }
      <article class="d-flex flex-column w-100 chat-message" [class.justify-content-end]="isCustomer(message.sender)">
        <div
          class="d-flex"
          [class.justify-content-end]="isCustomer(message.sender)"
          [class.justify-content-start]="!isCustomer(message.sender)"
        >
          <div
            class="bubble"
            [class.bubble-customer]="isCustomer(message.sender)"
            [class.bubble-consultant]="!isCustomer(message.sender)"
            [attr.data-testcafe]="testcafeLabel(message.createdAt, message.text)"
          >
            <span class="bubble-text vr-text-regular">{{ message.text }}</span>
            <span class="vr-text-label d-flex font-weight-bold time-stamp time-stamp-time">{{
              timestamp(message.createdAt)
            }}</span>
          </div>
        </div>
      </article>
    }

    <button
      mat-flat-button
      class="d-flex button-scroll button-scroll--down"
      [attr.data-testcafe]="'screenStart-button-scroll'"
      [class.hide]="isAtBottom"
      (click)="scrollToBottom()"
    >
      <kf-icon name="ic_chevron_unten_16" />
      <span>Letzte Nachrichten</span>
    </button>
  </div>
} @else {
  <div class="position-relative h-100 d-flex flex-column chat-frame">
    <div class="d-flex flex-grow-1 w-100 justify-content-center align-items-center">
      <p>Es sind noch keine Nachrichten vorhanden.</p>
    </div>
  </div>
}

<div class="messaging-functions">
  <span class="sr-only" id="messageFieldDescr">Eingabefeld für Nachrichten an den Berater</span>
  <textarea
    matInput
    [(ngModel)]="message"
    [attr.data-testcafe]="'screenStart-textarea-messageToConsultant'"
    class="w-100 mt-4 p-3 message-input"
    [placeholder]="'Ihre Nachricht'"
    aria-labelledby="messageFieldDescr"
  ></textarea>
  <div class="d-flex mt-4 messaging-controls justify-content-between" #ref>
    <button
      mat-flat-button
      color="primary"
      [attr.data-testcafe]="'screenStart-button-anwserConsultant'"
      [disabled]="isStringEmptyOrSpaces(message) || messageSending"
      (click)="sendMessage()"
    >
      <kf-icon name="ic_senden_24" />
      <span>Nachricht senden</span>
    </button>
    <button
      mat-stroked-button
      color="primary"
      [attr.data-testcafe]="'screenStart-button-update'"
      (click)="getMessageData()"
    >
      <span>Aktualisieren</span>
      <kf-icon name="ic_aktualisieren_24" />
    </button>
  </div>
</div>
