import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GetCustomerConsultationResponseItem } from '@domain/hub/consultation.domain';
import { ConsultationStatusEnum, GivenAnswerEnum, RoutingPathMain } from '@enums';
import { environment } from '@environment/environment';
import { AuthService } from '@modules/auth';
import { Action, ActionService } from '@services/action-service/action.service';
import { ClientService } from '@services/client-service/client.service';
import { ContextService } from '@services/context-service/context.service';
import { QueryService } from '@services/query-service/query.service';
import { color, libIcons } from 'bgzv-frontend-library';
import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'screen-start',
  templateUrl: './screen-start.component.html',
  styleUrls: ['./screen-start.component.scss'],
})
export class ScreenStartComponent implements OnInit, OnDestroy {
  private destroySubs = new Subject<void>();

  private customerId = '-1';
  public consultationId = '-1'; // comes in as the second url segment

  public GivenAnswerEnum = GivenAnswerEnum;
  public currentConsultation: GetCustomerConsultationResponseItem;
  public consultationData: GetCustomerConsultationResponseItem[] = [];

  public consultationDate: string;
  public currentConsultantData;
  public currentEnvironment: string = environment.platform;

  public readonly buttonIcon = libIcons;
  public readonly color = color;
  readonly moment = moment.locale('de');

  public loading: boolean = true;

  constructor(
    private actionService: ActionService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private clientService: ClientService,
    private contextService: ContextService,
    private queryService: QueryService,
    private router: Router
  ) {
    this.activatedRoute.params.subscribe(x => {
      // set customerId by param
      if (x.customerId) {
        this.customerId = x.customerId;
        this.clientService.customerId = x.customerId;
      }
      // set consultationId by param
      if (x.consultationId) {
        this.consultationId = x.consultationId;
      }
    });
  }

  ngOnInit(): void {
    //document.body.style.overflow = 'hidden';

    this.actionService.action.pipe(takeUntil(this.destroySubs)).subscribe(action => {
      if (action && action.target === 'prelim-start') {
      }
    });

    if (this.clientService.accessFromMain === true && environment.platform !== 'vp') {
      this.getPrelimByCustomerId();
    } else {
      this.queryService.getPreliminaries().subscribe(response => {
        const data = response
          .filter(consultation => consultation.status === ConsultationStatusEnum.preparationSent)
          .filter(x => {
            return x.id;
          });
        if (data === null && environment.platform !== 'vp') {
          this.getPrelimByCustomerId();
        } else {
          if (environment.platform === 'aws') {
            this.customerId = data[0].customerId;
            this.clientService.customerId = this.customerId;
            this.consultationId = data[0].id;
          }
          this.handleTopicsForPrelim(data);
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.destroySubs.next();
    this.destroySubs.complete();
  }

  public doAction(target: string = '', action: string = '', options?: any) {
    const data = { target: target, action: action } as Action;
    if (options) {
      data.options = options;
    }
    this.actionService.setAction(data);
  }

  public logout() {
    this.authService.logout();
    this.router.navigate([RoutingPathMain.Login]);
  }

  public setCurrentConsultation(consultation: GetCustomerConsultationResponseItem) {
    this.currentConsultation = consultation;

    this.clientService.instanceId = this.currentConsultation?.instanceId;
    this.clientService.consultationId = this.currentConsultation?.id;
    this.clientService.bankConsultId = this.currentConsultation?.id;
  }

  public backToAgenda(): void {
    window.location.href = '/services_cloud/portal/webcomp/kbm-agenda/uebersicht';
  }

  private getPrelimByCustomerId() {
    if (this.customerId != '-1') {
      this.clientService.customerId = this.customerId;
      this.queryService.getConsultationsByCustomerId(this.customerId).subscribe(consultationData => {
        const filteredData = consultationData
          .filter(consultation => consultation.status === ConsultationStatusEnum.preparationSent)
          .filter(x => {
            return x.id;
          });
        this.handleTopicsForPrelim(filteredData);
      });
    }
  }

  private handleTopicsForPrelim(consultationData: GetCustomerConsultationResponseItem[]) {
    this.consultationData = consultationData.sort((a, b) => {
      return new Date(b?.createdAt)?.valueOf() - new Date(a?.createdAt)?.valueOf();
    });

    this.setInitialTopic();
  }

  private setInitialTopic() {
    if (this.consultationData?.length > 0 && this.consultationId != '-1') {
      const activeConsultation = this.consultationData?.find(consultation => consultation?.id === this.consultationId);
      this.setCurrentConsultation(activeConsultation);
      this.clientService.consultationId = activeConsultation?.id;

      this.loading = false;
    } else {
      // we might want to display an error screen here
      console.log('Error: no consultation found.');
    }
  }

  get assetPath(): string {
    return this.contextService.assetPath;
  }
}
