@if (data.type !== 'simple') {
  <aside class="d-flex flex-column align-content-center vr-color-neutral-900 position-relative dialog-confirm">
    <div class="px-3">
      <div class="mb-5 mt-4 vr-headline-200">{{ data.questionText }}</div>
      <div class="vr-color-neutral-900 mb-1">
        Falls Ihr Berater eine Frage beantwortet hat, steht unter dieser Frage folgender Hinweis inklusive der Antwort:
      </div>
      <div class="vr-color-brand-primary font-weight-bold mb-5">Antwort Ihres Beraters</div>
      <div class="mb-5">Sie können Ihre Antworten zu jeder Zeit bearbeiten.</div>
      <div class="d-flex w-75 mb-4">
        <button mat-flat-button class="w-100 dialog-confirm-button" color="primary" (click)="onSelect()">
          <span>{{ data.confirmText }}</span>
        </button>
      </div>
    </div>
  </aside>
}

@if (data.type === 'simple') {
  <aside class="d-flex flex-column align-content-center vr-color-neutral-900 position-relative dialog-confirm">
    <header class="d-flex flex-column w-100 dialog-confirm-header">
      @if (data.showWarningIcon) {
        <vr-icon class="dialog-confirm-icon" [icon]="'vr-icon-warning'" [width]="'6rem'" [width]="'6rem'"></vr-icon>
      }
      <h2 class="vr-headline-300 vr-color-brand-primary-alternative mb-0 font-weight-bold" mat-dialog-title>
        {{ data.questionHeading ? data.questionHeading : 'Hinweis' }}
      </h2>
    </header>
    <div class="w-100 dialog-confirm-body">
      <mat-dialog-content>
        <div>{{ data.questionText }}</div>
      </mat-dialog-content>
    </div>
    <footer class="w-100 dialog-confirm-footer">
      <mat-dialog-actions class="justify-content-between">
        <!-- deny -->
        <button
          mat-stroked-button
          class="ml-3 dialog-confirm-button"
          color="primary"
          [attr.data-testcafe]="'dialogConfirm-button-no'"
          (click)="onSelect(false)"
        >
          <span>{{ data.denyText }}</span>
        </button>

        <!-- confirm -->
        @if (data.confirmText) {
          <button
            mat-flat-button
            class="mr-4 dialog-confirm-button"
            color="primary"
            [attr.data-testcafe]="'dialogConfirm-button-yes'"
            (click)="onSelect(true)"
          >
            <span>{{ data.confirmText }}</span>
          </button>
        }
      </mat-dialog-actions>
    </footer>
  </aside>
  @if (data.showClose) {
    <div class="position-absolute dialog-confirm-close">
      <button
        mat-icon-button
        color="primary"
        (click)="onClose()"
        title="Klicken oder ESC drücken, um zu schließen"
        label="Dialog schließen"
      >
        <kf-icon name="ic_schliessen_24" />
      </button>
    </div>
  }
}
