@if (useQuestionDataFields) {
  <ng-template *ngTemplateOutlet="contentQuestionDataFields"></ng-template>
}

<!-- CONTENT questionDataFields -->
<ng-template #contentQuestionDataFields>
  <div class="side-container">
    @if (questionDataFields?.length > 0) {
      @for (questionDataField of questionDataFields; track questionDataField) {
        <div class="vr-text-small mb-4 pb-2">
          @if (questionDataField.showGroupName) {
            <h3
              class="mb-3 vr-text-regular font-weight-bold"
              [attr.data-testcafe]="'dataFieldGroupQuestion-header-' + questionDataField.groupName"
            >
              {{ questionDataField.groupName }}
            </h3>
          }
          @if (questionDataField.template === 'PLAIN') {
            <data-field-group-plain [dataFieldGroup]="questionDataField"> </data-field-group-plain>
          }
          @if (questionDataField.template === 'TABLE') {
            <data-field-group-table [dataFieldGroup]="questionDataField"> </data-field-group-table>
          }
          @if (questionDataField.template === 'LIST') {
            <data-field-group-list [dataFieldGroup]="questionDataField"> </data-field-group-list>
          }
        </div>
      }
    }
  </div>
</ng-template>
