import { Component, Input, OnInit } from '@angular/core';
import { GetCustomerConsultationResponseItem } from '@domain/hub/consultation.domain';
import { QueryService } from '@services/query-service/query.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'consultant',
  templateUrl: './consultant.component.html',
  styleUrls: ['./consultant.component.scss'],
})
export class ConsultantComponent implements OnInit {
  @Input() data: GetCustomerConsultationResponseItem[];

  public loading: boolean = true;

  public currentConsultantData;

  constructor(private queryService: QueryService) {}

  ngOnInit(): void {
    this.getConsultantData();
  }

  private getConsultantData() {
    const consultantId = this.data && this.data[0]?.userId;

    this.queryService
      .getConsultants()
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(consultants => {
        this.currentConsultantData = consultants.find(consultant => consultant.id === consultantId);
      });
  }
}
