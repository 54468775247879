import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DataFieldGroupQuestion } from '@domain/app/data-field-group-question';
import { ClientService } from '@services/client-service/client.service';
import { QueryService } from '@services/query-service/query.service';
import { color, libIcons } from 'bgzv-frontend-library';
import { Subject } from 'rxjs';

import GroupDTO = DataFieldGroupQuestion.GroupDTO;

export const getValue = (data: DataFieldGroupQuestion.DataFieldItem, idx?: number): string => {
  if (!idx) idx = 0;
  if (data.values.length > 0) {
    if (data.values[idx]?.value === 'true') return 'Ja';
    if (data.values[idx]?.value === 'false') return 'Nein';
    return data.values[idx]?.value;
  } else {
    return '';
  }
};
@Component({
  selector: 'data-field-group-question',
  templateUrl: './data-field-group-question.component.html',
  styleUrls: ['./data-field-group-question.component.scss'],
})
export class DataFieldGroupQuestionComponent implements OnInit, OnDestroy {
  @Input() inputValues: any;

  private destroySubs = new Subject<void>();

  public formValid = true;
  public useQuestionDataFields = false;
  public questionDataFields: any;

  public getValue = getValue;
  readonly color = color;
  readonly buttonIcon = libIcons;

  constructor(
    private queryService: QueryService,
    private clientService: ClientService
  ) {}

  public ngOnInit(): void {
    this.useQuestionDataFields = this.inputValues?.isQuestionDataField || false;

    if (this.useQuestionDataFields) {
      const itemId = this.inputValues?.itemId;
      this.queryService.getQuestionDataFields(this.clientService.consultationId, itemId).subscribe(data => {
        this.questionDataFields = data.questionDataFieldGroups.flatMap(x => x.dataFieldGroups);
      });
    }
  }

  ngOnDestroy(): void {
    this.destroySubs.next();
    this.destroySubs.complete();
  }

  public getBooleanText(value: boolean): string {
    return value ? 'Ja' : 'Nein';
  }

  public displayableDataFieldGroups(Group: GroupDTO) {
    return Group.dataFieldGroups.filter(group => group);
  }

  public getTestcafeId(name: string) {
    return `${name?.replace(/ /g, '')}`;
  }
}
