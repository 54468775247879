import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { KfThemeModule } from '@app/kf-theme_module';
import { MaterialModule } from '@app/material_module';
import { BgzvComponentsModule } from '@components/components.module';
import { environment } from '@environment/environment';
import { BgzvMarginalsModule } from '@marginals/marginals.module';
import { AuthGuard } from '@modules/auth';
import { ScreenDoneComponent } from '@screens/screen-done/screen-done.component';
import { ScreenLoginComponent } from '@screens/screen-login/screen-login.component';
import { ScreenMainComponent } from '@screens/screen-main/screen-main.component';
import { ScreenStartComponent } from '@screens/screen-start/screen-start.component';
import { BgzvUtilsModule } from '@utils/utils.module';
import { BgzvLibraryModule } from 'bgzv-frontend-library';

export const screenRoutes: Routes = [
  {
    path: 'login',
    component: environment.platform === 'aws' ? ScreenLoginComponent : undefined,
    redirectTo: environment.platform === 'aws' ? undefined : 'start',
  },
  { path: 'start', component: ScreenStartComponent, canActivate: [AuthGuard] },
  { path: 'start/:customerId/:consultationId', component: ScreenStartComponent, canActivate: [AuthGuard] },
  { path: 'done', component: ScreenDoneComponent, canActivate: [AuthGuard] },
  { path: 'main/:consultationId', component: ScreenMainComponent, canActivate: [AuthGuard] },
];

@NgModule({
  declarations: [ScreenLoginComponent, ScreenStartComponent, ScreenMainComponent, ScreenDoneComponent],
  exports: [RouterModule],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,

    BgzvLibraryModule,
    BgzvComponentsModule,
    BgzvMarginalsModule,
    BgzvUtilsModule,
    KfThemeModule,
  ],
  providers: [],
})
export class BgzvScreensModule {}
