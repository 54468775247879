import { Component, OnDestroy, OnInit } from '@angular/core';
import { DialogConfirmData } from '@components/dialog-confirm/dialog-confirm.component';
import { DocumentsResponseItem } from '@domain/app/documents.domain';
import { DialogService } from '@services/dialog-service/dialog.service';
import { DocumentService } from '@services/document-service/document.service';
import { color } from 'bgzv-frontend-library';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'customer-documents',
  templateUrl: './customer-documents.component.html',
  styleUrls: ['./customer-documents.component.scss'],
})
export class CustomerDocumentsComponent implements OnInit, OnDestroy {
  private destroySubs = new Subject<void>();

  public readonly color = color;
  public documentsLoading: boolean = true;

  public consultationDocuments: DocumentsResponseItem[];

  constructor(
    private dialogService: DialogService,
    private documentService: DocumentService
  ) {}

  ngOnInit(): void {
    this.documentService.currentDocuments.pipe(takeUntil(this.destroySubs)).subscribe(documents => {
      this.documentsLoading = false;
      this.consultationDocuments = documents;
    });

    this.getDocuments();
  }

  ngOnDestroy(): void {
    this.destroySubs.next();
    this.destroySubs.complete();
  }

  public onDeleteClick(ev: Event, index: number) {
    const currentDocument = this.consultationDocuments[index];

    const data = {
      questionText: `Möchten Sie die Datei "${currentDocument.filename}" endgültig löschen?`,
      denyText: 'Nein',
      confirmText: 'Ja',
      target: ev.target,
      context: 'Dokumente',
      type: 'simple',
    } as DialogConfirmData;

    let dialogRef = this.dialogService.openDialogConfirm(data);

    dialogRef.afterClosed().subscribe(result => {
      if (result?.confirmed === true) {
        this.documentService.deselectDocument(currentDocument);
      }
      dialogRef = null;
    });
  }

  private getDocuments() {
    this.documentService.fetchDocumentList();
  }
}
