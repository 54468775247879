import { ThemeInterceptor } from '@utils/interceptors/theme.interceptor';

export const ENVIRONMENT_DEFAULTS = {
  production: false,
  enableOnlinePing: false,
  enableDebug: true,
  enableLogging: false,
  useUserback: false,
  isLocal: false,

  platform: 'aws',
  apiPrefix: 'api',
  apiVersion: 'v1',
  hubPrefix: 'hub',
  hubVersion: 'v1',
  consultationType: 'prelim',

  consultationOriginSuffix: 'guide.bg.bankingguide.de',
  preliminaryTargetSuffix: 'pre.bg.bankingguide.de',

  cognitoUserPoolId: 'eu-central-1_abcdefghi',
  cognitoClientId: 'aaabbbcccdddeeefffggghhhii',

  get api() {
    return null;
  },

  get hub() {
    return null;
  },

  get consultationOrigin() {
    return ``;
  },
  get preliminaryTarget() {
    return ``;
  },
};

const themeInterceptor = new ThemeInterceptor();
