import { Injectable } from '@angular/core';
import { CustomerContactResponseItem } from '@domain/app/contact.domain';
import { ConsultationStatusEnum } from '@enums';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  constructor() {}

  private _instanceId: string;
  public instanceIdChanged = new BehaviorSubject(null);

  private _customerId: string;
  private _customerName: string;
  private _customerLogo: string;

  private _consultationId: string;
  private _consultationStatus: ConsultationStatusEnum;
  private _bankConsultId: string;
  private _bankExternalId: string;
  private _bankConsultEmail: string;
  private _numSolutions = -1;
  private _prepDataSent = false;
  private _hasVatDiscount = false;

  private _accessFromMain = false;

  private _sessionContacts: CustomerContactResponseItem[] = [];

  public get instanceId(): string {
    return this._instanceId;
  }
  public set instanceId(value: string) {
    this._instanceId = value;
    this.instanceIdChanged.next(value);
  }

  public get customerId() {
    return this._customerId;
  }
  public set customerId(value) {
    this._customerId = value;
  }

  public get customerName() {
    return this._customerName;
  }
  public set customerName(value) {
    this._customerName = value;
  }

  public get customerLogo(): string {
    return this._customerLogo;
  }
  public set customerLogo(value: string) {
    this._customerLogo = value;
  }

  public get consultationId(): string {
    return this._consultationId;
  }
  public set consultationId(value: string) {
    this._consultationId = value;
  }

  public get consultationStatus(): ConsultationStatusEnum {
    return this._consultationStatus;
  }
  public set consultationStatus(value: ConsultationStatusEnum) {
    this._consultationStatus = value;
  }

  public get bankConsultId(): string {
    return this._bankConsultId;
  }
  public set bankConsultId(value: string) {
    this._bankConsultId = value;
  }

  public get bankExternalId(): string {
    return this._bankExternalId;
  }
  public set bankExternalId(value: string) {
    this._bankExternalId = value;
  }

  public get bankConsultEmail(): string {
    return this._bankConsultEmail;
  }
  public set bankConsultEmail(value: string) {
    this._bankConsultEmail = value;
  }

  public get numSolutions(): number {
    return this._numSolutions;
  }
  public set numSolutions(value: number) {
    this._numSolutions = value;
  }

  public get prepDataSent(): boolean {
    return this._prepDataSent;
  }
  public set prepDataSent(value: boolean) {
    this._prepDataSent = value;
  }

  public get hasVatDiscount() {
    return this._hasVatDiscount;
  }
  public set hasVatDiscount(value) {
    this._hasVatDiscount = value;
  }

  public get sessionContacts(): CustomerContactResponseItem[] {
    return this._sessionContacts;
  }

  public set sessionContacts(value: CustomerContactResponseItem[]) {
    this._sessionContacts = value;
  }

  public get accessFromMain() {
    return this._accessFromMain;
  }
  public set accessFromMain(value) {
    this._accessFromMain = value;
  }

  public addSessionContact(data: CustomerContactResponseItem) {
    const result = this.sessionContacts.find(x => x.firstName === data.firstName && x.lastName === data.lastName);

    if (!result) {
      this.sessionContacts.push(data);
    } else {
      Object.keys(data).map(x => {
        result[x] = data[x];
      });
    }
  }

  public removeSessionContact(data: CustomerContactResponseItem) {
    this.sessionContacts = this.sessionContacts.filter(
      x => x.firstName !== data.firstName && x.lastName !== data.lastName
    );
  }
}
