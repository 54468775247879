import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UpdateAnswerItem, UpdateAnswerResponse } from '@domain/app/answers.domain';
import { ConfigurationResponse } from '@domain/app/configuration.domain';
import {
  AddToPreliminaryRequestItem,
  ManageConsultationRequestItem,
  SelectForPreliminaryRequestItem,
  SendPreliminaryRequestItem,
  SendPreliminaryResponseItem,
  UpdateConsultationStatusRequestItem,
} from '@domain/app/consultation.domain';
import { CustomerContactResponseItem } from '@domain/app/contact.domain';
import { DataFieldGroupQuestion } from '@domain/app/data-field-group-question';
import { DataFieldGroupResponse, DataFieldGroupsOverviewResponse } from '@domain/app/datafield.domain';
import { DocumentsRequestItem, DocumentsResponseItem } from '@domain/app/documents.domain';
import { MessagesRequestItem, MessagesResponseItem } from '@domain/app/messages.domain';
import { QuestionGroupResponse } from '@domain/app/question.domain';
import { QuestionGroupRecommendationResponse } from '@domain/app/recommendation.domain';
import { SelectedTopicItem, TopicSelectionResponse } from '@domain/app/topic.domain';
import { CacheService } from '@services/cache-service/cache.service';
import { ClientService } from '@services/client-service/client.service';
import { Observable } from 'rxjs';
import { QueryHub } from './query.hub.service';
import GetDataFieldGroupQuestionResponse = DataFieldGroupQuestion.GetDataFieldGroupQuestionResponse;

@Injectable({
  providedIn: 'root',
})
export class QueryService extends QueryHub {
  constructor(
    protected override http: HttpClient,
    protected override cacheService: CacheService,
    private clientService: ClientService
  ) {
    super(http, cacheService);
  }

  // -------------------------------------------------------------------------------------- //
  // ---------------------------------- APP GET METHODS ----------------------------------- //
  // -------------------------------------------------------------------------------------- //

  // ========================================== //
  // ======== IMPLEMENTED WITH BACKEND ======== //
  // ========================================== //

  getConfiguration(): Observable<ConfigurationResponse> {
    // this data can be cached as it will not change during the runtime of the consultation
    return this.cacheService.fetch('config', () => this.http.get<ConfigurationResponse>(`${this.api}/configuration`));
  }

  getCustomerContacts(consultationId: string): Observable<CustomerContactResponseItem[]> {
    return this.http.get<CustomerContactResponseItem[]>(
      `${this.api}/consultations/${consultationId}/customer-contacts`
    );
  }

  getTopicDataByConsultationId(consultationId: string, instanceId: string = null): Observable<TopicSelectionResponse> {
    const headers = new HttpHeaders().set('instance-id', String(instanceId));
    const options = { headers };
    return this.http.get<TopicSelectionResponse>(
      `${this.api}/consultations/${consultationId}/preliminary/available`,
      options
    );
  }

  getQuestionCollectionById(consultationId: string, questionCollectionId): Observable<QuestionGroupResponse> {
    return this.http.get<QuestionGroupResponse>(
      `${this.api}/consultations/${consultationId}/preliminary/question-groups/${questionCollectionId}`
    );
  }

  getRecommendedProducts(
    consultationId: string,
    questionGroupId: string
  ): Observable<QuestionGroupRecommendationResponse> {
    return this.http.get<QuestionGroupRecommendationResponse>(
      `${this.api}/consultations/${consultationId}/recommendations/question-groups/${questionGroupId}`
    );
  }

  /// --- DATA FIELD ENDPOINTS --- ///
  getDataFieldOverview(consultationId: string): Observable<DataFieldGroupsOverviewResponse[]> {
    return this.http.get<DataFieldGroupsOverviewResponse[]>(
      `${this.api}/consultations/${consultationId}/data-field-groups/overview`
    );
  }

  getGeneralDataFieldGroup(consultationId: string): Observable<DataFieldGroupResponse> {
    return this.http.get<DataFieldGroupResponse>(
      `${this.api}/consultations/${consultationId}/data-field-groups/general`
    );
  }

  getProductDataFieldGroupById(consultationId: string, productId: string): Observable<DataFieldGroupResponse> {
    return this.http.get<DataFieldGroupResponse>(
      `${this.api}/consultations/${consultationId}/data-field-groups/products/${productId}`
    );
  }

  getTransitionDataFieldGroupById(consultationId: string, transistionId: string): Observable<DataFieldGroupResponse> {
    return this.http.get<DataFieldGroupResponse>(
      `${this.api}/consultations/${consultationId}/data-field-groups/transitions/${transistionId}`
    );
  }

  // ========================================== //
  // ====== NOT IMPLEMENTED WITH BACKEND ====== //
  // ========================================== //

  getConsultationDatesByClientId(customerId): Observable<any> {
    return this.http.get<any>(`${this.api}/consultationDatesByClientId/${customerId}`);
  }

  getDataFieldGroupQuestionByClientId(customerId): Observable<any> {
    return this.http.get<any>(`${this.api}/profileByClientId/${customerId}`);
  }

  getConsultationMessages(consultationId: string): Observable<MessagesResponseItem[]> {
    return this.http.get<MessagesResponseItem[]>(`${this.api}/consultations/${consultationId}/messages`);
  }

  getConsultationDocuments(consultationId: string): Observable<DocumentsResponseItem[]> {
    return this.http.get<DocumentsResponseItem[]>(`${this.api}/consultations/${consultationId}/preliminary/documents`);
  }

  // -------------------------------------------------------------------------------------- //
  // ---------------------------------- APP PUT METHODS ----------------------------------- //
  // -------------------------------------------------------------------------------------- //

  // ========================================== //
  // ======== IMPLEMENTED WITH BACKEND ======== //
  // ========================================== //

  putConsultation(consultationId: string, data: ManageConsultationRequestItem, propagate = true): Observable<any> {
    const sendData = JSON.stringify(data);
    const propagateQuery = propagate ? '?propagate=true' : '';

    return this.http.put<any>(`${this.api}/consultations/${consultationId}${propagateQuery}`, sendData, this.options);
  }

  putConsultationStatus(consultationId: string, data: UpdateConsultationStatusRequestItem): Observable<any> {
    const sendData = JSON.stringify(data);
    return this.http.put<any>(`${this.api}/consultations/${consultationId}/update-status`, sendData, this.options);
  }

  putAddQuestionGroupToPreliminary(consultationId: string, data: AddToPreliminaryRequestItem): Observable<any> {
    const sendData = JSON.stringify(data);
    return this.http.put<any>(`${this.api}/consultations/${consultationId}/add-to-preliminary`, sendData, this.options);
  }

  putSelectTopicForPreliminary(consultationId: string, data: SelectForPreliminaryRequestItem): Observable<any> {
    const sendData = JSON.stringify(data);
    return this.http.put<any>(
      `${this.api}/consultations/${consultationId}/select-for-preliminary`,
      sendData,
      this.options
    );
  }

  putSendFinishPreliminary(
    consultationId: string,
    data: SendPreliminaryRequestItem
  ): Observable<SendPreliminaryResponseItem> {
    const sendData = JSON.stringify(data);
    return this.http.put<SendPreliminaryResponseItem>(
      `${this.api}/consultations/${consultationId}/send-preliminary`,
      sendData,
      this.options
    );
  }

  putSubtopicComplete(consultationId: string, subtopicId: string): Observable<any> {
    const sendData = JSON.stringify(subtopicId);
    return this.http.put<any>(
      `${this.api}/consultations/${consultationId}/subtopics/${subtopicId}/complete`,
      sendData,
      this.options
    );
  }

  putTopicDataByConsultationId(consultationId: string, data: SelectedTopicItem[]): Observable<any> {
    const sendData = JSON.stringify(data);
    return this.http.put<any>(`${this.api}/consultations/${consultationId}/topics`, sendData, this.options);
  }

  putAnswerFromGroup(
    consultationId: string,
    questionId: string,
    answerObject: UpdateAnswerItem[]
  ): Observable<UpdateAnswerResponse> {
    const sendData = JSON.stringify(answerObject);
    // return this.http.post<any>('/mock/sendAnswersFromGroup', sendData);
    return this.http.put<UpdateAnswerResponse>(
      `${this.api}/consultations/${consultationId}/questions/${questionId}/answers`,
      sendData,
      this.options
    );
  }

  getQuestionDataFields(consultationId: string, questionId: string): Observable<any> {
    return this.http.get<any>(`${this.api}/consultations/${consultationId}/questions/${questionId}/data-fields`);
  }

  getDataFieldGroupQuestion(
    consultationId: string,
    showConfidentialGroups: boolean
  ): Observable<GetDataFieldGroupQuestionResponse> {
    return this.http.get<any>(
      `${this.api}/consultations/${consultationId}/profile?showConfidential=${showConfidentialGroups}`
    );
  }

  getMediaContent(mediaUrl: string, renew = false): Observable<any> {
    const options = { responseType: 'blob' as 'json' };
    const params = `?url=${mediaUrl}`;
    return this.cacheService.fetch(
      `mediaContent_${mediaUrl}`,
      () => this.http.get(`${this.api}/content/medias/download${params}`, options),
      renew
    );
  }

  // -------------------------------------------------------------------------------------- //
  // ---------------------------------- APP POST METHODS ----------------------------------- //
  // -------------------------------------------------------------------------------------- //
  postMessage(consultationId: string, messageData: MessagesRequestItem) {
    const sendData = JSON.stringify(messageData);
    return this.http.post<any>(`${this.api}/consultations/${consultationId}/messages`, sendData, this.options);
  }

  /*   postUploadedDocument(consultationId: number, file: File): Observable<DocumentsResponseItem> {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post<any>(
      `${this.api}/consultations/${consultationId}/preliminary/documents`,
      formData,
      this.options
    );
  } */

  // ========================================== //
  // ====== NOT IMPLEMENTED WITH BACKEND ====== //
  // ========================================== //

  putPrepQuestionsToCustomer(consultationId: string, questionData) {
    const sendData = JSON.stringify(questionData);
    return this.http.put<any>(`${this.api}/preparation/${consultationId}/questions`, sendData, this.options);
  }

  // -------------------------------------------------------------------------------------- //
  // ------------------------------ APP POST/PATCH METHODS -------------------------------- //
  // -------------------------------------------------------------------------------------- //

  // ========================================== //
  // ======== IMPLEMENTED WITH BACKEND ======== //
  // ========================================== //

  patchCheckoutConfimOrder(consultationId: string) {
    return this.http.patch<any>(`${this.api}/consultations/${consultationId}/checkout/confirm-order`, {}, this.options);
  }

  postCreateConsultation(data: ManageConsultationRequestItem): Observable<any> {
    const sendData = JSON.stringify(data);
    return this.http.post<any>(`${this.api}/consultations/`, sendData, this.options);
  }

  postSelectDocumentForConsultation(consultationId: string, gshInfo: DocumentsRequestItem): Observable<any> {
    return this.http.post<any>(` ${this.api}/consultations/${consultationId}/preliminary/documents`, gshInfo);
  }

  // ========================================== //
  // ====== NOT IMPLEMENTED WITH BACKEND ====== //
  // ========================================== //

  // ---------------------------------------------- //
  // --------------- DELETE METHODS --------------- //
  // ---------------------------------------------- //

  deleteSelectDocumentsForConsultation(consultationId: string, gshId: string): Observable<any> {
    return this.http.delete<any>(` ${this.api}/consultations/${consultationId}/preliminary/documents/${gshId}`);
  }

  deleteSelectDocumentsForCustomer(customerId: string, gshId: string): Observable<any> {
    return this.http.delete<any>(` ${this.hub}/customers/${customerId}/documents/${gshId} `);
  }

  // ========================================== //
  // ======== IMPLEMENTED WITH BACKEND ======== //
  // ========================================== //

  deleteIndividualTask(consultationId: string, taskId: string): Observable<any> {
    return this.http.delete<any>(`${this.api}/consultations/${consultationId}/individual-tasks/${taskId}`);
  }

  // ========================================== //
  // ====== NOT IMPLEMENTED WITH BACKEND ====== //
  // ========================================== //
}
