import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { QuestionGroupResponse } from '@domain/app/question.domain';
import { QuestionGroupOverviewItem, SubtopicOverviewItem, TopicOverviewItem } from '@domain/app/topic.domain';
import { TopicStatusEnum } from '@enums';
import { ContextService } from '@services/context-service/context.service';
import { color } from 'bgzv-frontend-library';
import { Subscription } from 'rxjs';

export interface ItemMenuOptions {
  mainMarginBottom?: string;
  mainMarginLeft?: string;
  headerHeight?: string;
  groupIndent?: string;
  groupCursor?: string;
  singleTopic?: boolean;
  startExpanded?: boolean;
  isDisabled?: boolean;
}

@Component({
  selector: 'item-menu-structure',
  templateUrl: './item-menu-structure.component.html',
  styleUrls: ['./item-menu-structure.component.scss'],
})
export class ItemMenuStructureComponent implements OnInit, OnDestroy {
  @Input() data: TopicOverviewItem;
  @Input() index: number;
  @Input() useIndex: boolean;
  @Input() displayType: 'progress' | 'none' | 'check' | 'select' | 'select-all' | 'topic-list';
  @Input() options: ItemMenuOptions;
  @Input() onlySelected: boolean;

  // TODO: Interface
  @Output() subItemSelected = new EventEmitter<[boolean, SubtopicOverviewItem]>();
  @Output() groupItemSelected = new EventEmitter<[boolean, QuestionGroupOverviewItem]>();

  color = color;
  status = TopicStatusEnum;
  defaultOptions: ItemMenuOptions;
  currentQuestionGroup: QuestionGroupResponse;
  _questionGroupSub: Subscription;

  constructor(private contextService: ContextService) {
    this.index = 0;
    this.useIndex = false;
    this.onlySelected = true;
    this.displayType = 'none';

    this.defaultOptions = {
      mainMarginBottom: '1.5rem',
      mainMarginLeft: '1.5rem',
      headerHeight: '3rem',
      groupIndent: '1.5rem',
      groupCursor: 'default',
      singleTopic: false,
      startExpanded: false,
      isDisabled: false,
    };
  }

  ngOnInit(): void {
    this.options = { ...this.defaultOptions, ...this.options };
    this._questionGroupSub = this.contextService.currentQuestionGroup.subscribe(currentQuestionGroup => {
      this.currentQuestionGroup = currentQuestionGroup;
    });
  }

  ngOnDestroy(): void {
    this._questionGroupSub?.unsubscribe();
  }

  // TODO: Interface - make one object
  public handleInnerItemSelect(checked: boolean, item: SubtopicOverviewItem) {
    if (this.displayType === 'select-all') {
      for (const qg of item.questionGroups) {
        qg.selected = checked;
      }
    }
    this.subItemSelected.emit([checked, item]);
  }

  public handleQuestionGroupSelect(checked: boolean, item: QuestionGroupOverviewItem) {
    this.groupItemSelected.emit([checked, item]);
  }

  public hasPrelimSelectedItems(subtopic) {
    return subtopic?.find(y => y.showInPreliminary);
  }

  public getQuestionGroups(subtopics) {
    return subtopics.flatMap(subtopic => subtopic.questionGroups);
  }

  public click(event, target: any) {
    if (event.code === 'Enter' || event.code === 'Space') {
      target.click();
    }
  }
}
