import { Pipe, PipeTransform } from '@angular/core';
import { QueryService } from '@services/query-service/query.service';
import { lastValueFrom } from 'rxjs';

@Pipe({
  name: 'replacement',
})
export class ReplacementPipe implements PipeTransform {
  constructor(private queryService: QueryService) {}

  async transform(key: string, type: string = null): Promise<string> {
    const configData = await lastValueFrom(this.queryService.getConfiguration());
    const data = configData.bankInformation.find(x => x.key === key) || configData.settings.find(x => x.key === key);

    if (data) {
      if (!type) {
        return data.value;
      } else if (data[type]) {
        return data[type];
      }
    }
    return '';
  }
}
