import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { KfThemeModule } from '@app/kf-theme_module';
import { MaterialModule } from '@app/material_module';
import { BgzvComponentsModule } from '@components/components.module';
import { RoutingPathOverlay } from '@enums';
import { BgzvMarginalsModule } from '@marginals/marginals.module';
import { OverlayDebugComponent } from '@overlays/overlay-debug/overlay-debug.component';
import { BgzvUtilsModule } from '@utils/utils.module';
import { BgzvLibraryModule } from 'bgzv-frontend-library';

export const overlayRoutes: Routes = [
  // overlay paths
  { path: RoutingPathOverlay.Debug.valueOf(), component: OverlayDebugComponent, outlet: 'overlay' },
];

@NgModule({
  declarations: [OverlayDebugComponent],
  exports: [RouterModule],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,

    BgzvLibraryModule,
    BgzvMarginalsModule,
    BgzvComponentsModule,
    BgzvUtilsModule,
    KfThemeModule,
  ],
  providers: [],
})
export class BgzvOverlayModule {}
