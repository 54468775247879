import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { QueryService } from '@services/query-service/query.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  public isVp = environment.platform === 'vp';
  constructor(private queryService: QueryService) {}

  public async getMediaContent(url: string): Promise<string> {
    if (this.isVp && url.includes('./assets')) {
      return url;
    }
    try {
      const mediaData = await lastValueFrom(this.queryService.getMediaContent(url));
      return (await this.blobToBase64(mediaData)) as string;
    } catch (error) {
      return '';
    }
  }

  private blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }
}
