import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GetCustomerConsultationResponseItem } from '@domain/hub/consultation.domain';
import { RoutingPathMain } from '@enums';
import { ClientService } from '@services/client-service/client.service';
import { QueryService } from '@services/query-service/query.service';
import { color, libIcons } from 'bgzv-frontend-library';
import moment from 'moment';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.scss'],
})
export class AgendaComponent implements OnInit {
  @Input() currentConsultation: GetCustomerConsultationResponseItem;
  private customerId = -1;

  public topicContent = [];
  public readonly buttonIcon = libIcons;
  public readonly color = color;

  public topicLoading: boolean = true;

  readonly moment = moment.locale('de');

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private queryService: QueryService,
    private clientService: ClientService
  ) {
    // set customerId by Param
    this.activatedRoute.params.subscribe(x => {
      if (x.customerId) {
        this.customerId = x.customerId;
        this.clientService.customerId = x.customerId;
      }
    });
  }

  ngOnInit(): void {
    this.getTopics();
  }

  public getTopicDate(): string {
    return moment(this.currentConsultation?.appointmentDate).format('dddd, DD. MMMM YYYY');
  }

  public getTopicTime(): string {
    return `${this.currentConsultation?.appointmentTime} Uhr`;
  }

  public getTopicText(): string {
    return this.currentConsultation?.name;
  }

  private getTopics() {
    this.topicContent = [];
    this.queryService
      .getTopicDataByConsultationId(this.currentConsultation?.id, this.currentConsultation?.instanceId)
      .pipe(finalize(() => (this.topicLoading = false)))
      .subscribe(topics => {
        this.topicContent = topics.topics
          .flatMap(x => x.subtopics)
          .filter(z => z.showInPreliminary)
          .map(y => y.name);
      });
  }

  public onStartPrelim(ev: Event) {
    this.clientService.instanceId = this.currentConsultation?.instanceId;
    this.router.navigate([`${RoutingPathMain.Main}/${this.currentConsultation?.id}`]);
  }

  get noTopicsAvailable(): boolean {
    return !this.topicContent ? true : this.topicContent.length === 0;
  }
}
