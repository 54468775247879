import { Injectable, OnDestroy } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LeftSidenavService implements OnDestroy {
  private _animationSub: Subscription;
  private _sidenavInstance: MatSidenav;

  private _context = '';
  private _origContext = '';

  constructor() {}

  ngOnDestroy(): void {
    if (this._animationSub) {
      this._animationSub.unsubscribe();
    }
  }

  public setSidenav(sidenav: MatSidenav) {
    this._sidenavInstance = sidenav;
    this._animationSub = this._sidenavInstance._animationEnd.subscribe(instance => {
      // reset original state on close, if 'useOnce' was used
      if (instance.fromState === 'open' && instance.toState === 'void' && this._origContext !== '') {
        this._context = this._origContext;
        this._origContext = '';
      }
    });
  }

  public open(): Promise<any> {
    const promise = new Promise((resolve, reject) => {
      this._sidenavInstance.open().then(() => resolve(null));
    });
    return promise;
  }

  public close(): Promise<any> {
    const promise = new Promise((resolve, reject) => {
      this._sidenavInstance.close().then(x => {
        resolve(null);
      });
    });
    return promise;
  }

  public toggle(): Promise<any> {
    const promise = new Promise((resolve, reject) => {
      this._sidenavInstance.toggle().then(() => resolve(null));
    });
    return promise;
  }

  public use(context: string) {
    this._context = context;
  }

  public useOnce(tempContext: string) {
    if (this.context !== tempContext) {
      this._origContext = this._context;
      this._context = tempContext;
    }
  }

  public set context(context: string) {
    this._context = context;
  }

  public get context() {
    return this._context;
  }

  public get instance() {
    return this._sidenavInstance;
  }

  public set origContext(context: string) {
    this._origContext = context;
  }

  public get origContext() {
    return this._origContext;
  }
}

@Injectable({
  providedIn: 'root',
})
export class RightSidenavService extends LeftSidenavService {}
