import { Injectable } from '@angular/core';
import isEqual from 'lodash-es/isEqual';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class CacheService {
  private storage: { key: any; value: any }[] = [];

  fetch<Key, Value>(key: Key, generator: () => Observable<Value>, forceRenew: boolean = false): Observable<Value> {
    const cachedEntry = this.storage.find(entry => isEqual(entry.key, key));
    if (cachedEntry !== undefined && !forceRenew) {
      return of(<Value>cachedEntry.value);
    } else {
      return generator().pipe(tap(value => this.put(key, value)));
    }
  }

  private put<Key, Value>(key: Key, value: Value): void {
    this.storage.push({ key: key, value: value });
  }
}
