<header class="d-flex bg-white content-width position-relative prelim-header">
  <kf-compact-button
    type="secondary"
    class="mr-2 cursor-pointer"
    color="primary"
    iconName="ic_pfeil_links_32"
    (click)="[sendTempTextRequest(), backtoStartScreen()]"
    title="Zurück zur Startseite"
  />
  <h2 class="vr-headline-300 font-weight-bold vr-color-brand-primary-alternative">Vorbefragung</h2>
</header>

<main
  class="bg-white d-flex flex-wrap {{
    !questionGroupLoading ? 'justify-content-between' : 'justify-content-center contents-loading'
  }} content-width"
>
  @if (!questionGroupLoading) {
    <section class="d-flex flex-column marginals-main-section">
      <ng-template *ngTemplateOutlet="menuContainer; context: { $implicit: menuData }"></ng-template>
    </section>
    <section class="d-flex flex-column main-section">
      <ng-template *ngTemplateOutlet="questionContainer; context: { $implicit: {} }"></ng-template>
    </section>
  } @else {
    <!-- ---------- LOADING SCREEN --------- -->
    <loading-screen></loading-screen>
  }
</main>

<ng-template #menuContainer let-menuData>
  @if (menuData) {
    <div class="d-flex flex-column w-100">
      <em class="font-weight-bold mb-3 sr-only">Beratungsthemen</em>
      <div class="overflow-auto" tabindex="-1">
        <div class="d-flex w-100 flex-column">
          @for (item of menuData; track item; let i = $index) {
            <item-menu-structure
              class="w-100 mb-2"
              [data]="item"
              [useIndex]="true"
              [index]="i"
              [displayType]="'topic-list'"
              [onlySelected]="true"
              (groupItemSelected)="jumpToQuestionGroup($event)"
            ></item-menu-structure>
          }
        </div>
      </div>
    </div>
  }
</ng-template>

<ng-template #questionContainer let-questionData>
  @if (!questionCollectionError) {
    <div class="d-flex flex-column flex-grow-1 w-100">
      <div
        class="d-flex flex-column w-100"
        tabindex="-1"
        [class.flex-scroll]="!questionGroupLoading"
        [class.overflow-hidden]="questionGroupLoading"
      >
        @if (questionGroupData) {
          <div class="d-flex align-self-start align-items-center mb-3" style="min-height: 1.25rem">
            <strong
              [debugId]="questionGroupData.subtopicId"
              class="vr-text-label mr-3"
              innerHtml="{{ questionGroupData.topic || '' }}"
            ></strong>
            @for (items of helperArray(questionGroupData.totalCollections).fill(1); track items; let i = $index) {
              <div
                class="progress-pill progress-pill-before mr-1"
                [class.progress-pill-current]="i === questionGroupData.currentCollection"
                [class.progress-pill-after]="i < questionGroupData.currentCollection"
              ></div>
            }
          </div>
          <div class="d-flex w-100 flex-scroll question-container-height">
            <div tabindex="-1" class="d-flex flex-column flex-grow-1 flex-scroll w-100" #questionGroupContainer>
              @if (questionGroupData) {
                @if (questionGroupData.headline) {
                  <h3
                    [debugId]="questionGroupData.collectionId"
                    class="question-group-headline"
                    [innerHTML]="questionGroupData.headline | safeHtml"
                  ></h3>
                }
                @if (questionGroupData.description) {
                  <div
                    class="vr-text-regular vr-color-neutral-700 mt-3 pr-5"
                    [innerHTML]="questionGroupData.description | safeHtml"
                  ></div>
                }
                @if (imageData.length > 1) {
                  <div class="mt-3">
                    <vr-image [imageMulti]="imageData" [multi]="true" [width]="'100%'" [height]="'300px'"></vr-image>
                  </div>
                }
                @if (imageData.length === 1) {
                  <div class="mt-3">
                    <vr-image [image]="imageData[0]" [showIcon]="true" [width]="'50%'" [height]="'200px'"></vr-image>
                  </div>
                }
                @for (question of questionGroupData.questions; track question; let i = $index) {
                  @if (isQuestionShown(question)) {
                    <div class="mt-5 ml-1">
                      <!-- ------ HEADER OF QUESTION ------ -->
                      <div
                        [debugId]="question.id"
                        [innerHTML]="
                          (isDebugInQuestionHeadingShown(question) ? 'DEBUG ' : '') + question.type ===
                          questionEnum.connectedSlider
                            ? getSliderQuestionHeader(question.name)
                            : (question.name | safeHtml)
                        "
                        class="vr-text-large vr-color-neutral-900 mb-3"
                      ></div>
                      <div
                        [innerHTML]="question.label ? question.label : ''"
                        class="vr-text-regular vr-color-neutral-700 mb-3 question-label"
                      ></div>
                      <!-- ------ IMAGE OF QUESTION ------ -->
                      @if (hasImage(question)) {
                        <vr-image
                          [image]="getQuestionImage(question)"
                          [behavior]="question.imageDefaultSize"
                        ></vr-image>
                      }
                      @if (!isQuestionPaired(question)) {
                        @if (question.type === questionEnum.multi) {
                          <ng-template *ngTemplateOutlet="multiQuestion; context: { $implicit: question }">
                          </ng-template>
                        } @else if (question.type === questionEnum.single) {
                          <ng-template *ngTemplateOutlet="singleQuestion; context: { $implicit: question }">
                          </ng-template>
                        } @else if (
                          question.type === questionEnum.multiImage || question.type === questionEnum.singleImage
                        ) {
                          <ng-template *ngTemplateOutlet="imageQuestion; context: { $implicit: question }">
                          </ng-template>
                        } @else if (
                          question.type === questionEnum.slider ||
                          question.type === questionEnum.connectedSlider ||
                          question.type === questionEnum.expSlider
                        ) {
                          <slider
                            [question]="question"
                            [sliderPropertiesMap]="sliderPropertiesMap"
                            [numericalAnswerData]="numericalAnswerData"
                            [MinMax]="numericalAnswerMinMax"
                            (sliderSelected)="handleQuery()"
                          />
                        } @else if (question.type === questionEnum.number) {
                          <ng-template *ngTemplateOutlet="numberQuestion; context: { $implicit: question }">
                          </ng-template>
                          <ng-template *ngTemplateOutlet="textField; context: { $implicit: question }"> </ng-template>
                        } @else if (question.type === questionEnum.toggle) {
                          <ng-template *ngTemplateOutlet="toggleQuestion; context: { $implicit: question }">
                          </ng-template>
                        } @else if (question.type === questionEnum.segments) {
                          <ng-template *ngTemplateOutlet="segmentQuestion; context: { $implicit: question }">
                          </ng-template>
                          <ng-template *ngTemplateOutlet="textField; context: { $implicit: question }"> </ng-template>
                        } @else if (question.type === questionEnum.text) {
                          <ng-template *ngTemplateOutlet="textQuestion; context: { $implicit: question }">
                          </ng-template>
                        } @else if (question.type === questionEnum.dataFieldGroup) {
                          <ng-template
                            *ngTemplateOutlet="dataFieldGroupQuestion; context: { $implicit: question }"
                          ></ng-template>
                        }
                      }
                      @if (isQuestionPaired(question)) {
                        <div
                          class="d-flex flex-column vr-color-neutral-700 mt-3 p-4"
                          [style.backgroundColor]="color.find(color.name.Neutral100)"
                        >
                          <div class="font-weight-bold">
                            {{ getPairedQuestionAnswer(question).length > 1 ? 'Antworten' : 'Antwort' }}
                          </div>
                          <div class="d-flex">
                            {{ getPairedQuestionAnswer(question).join(' | ') }}
                          </div>
                        </div>
                      }
                      @if (otherAnswersMap && otherAnswersMap.get(question.id)) {
                        @if (otherAnswersMap.get(question.id).CONSULTANT.length > 0) {
                          <div class="vr-color-brand-primary mt-3">
                            <div class="font-weight-bold">
                              Antwort{{ otherAnswersMap.get(question.id).CONSULTANT.length > 1 ? 'en' : '' }} Ihres
                              Beraters:&nbsp;
                            </div>
                            @for (
                              answer of otherAnswersMap.get(question.id).CONSULTANT;
                              track answer;
                              let last = $last
                            ) {
                              <div [attr.data-testcafe]="testcafeLabel(question.id, answer)">
                                {{ answer }}{{ !last ? ',&nbsp;' : '' }}
                              </div>
                            }
                          </div>
                        }
                      }
                    </div>
                  }
                  @if (isQuestionShown(question) && question.syncedQuestions && question.syncedQuestions.length > 0) {
                    <div class="ml-3">
                      <div class="d-flex cursor-pointer synced-question-button-pill">
                        <button mat-flat-button color="primary" (click)="changeSyncQuestionState(question.id)">
                          <span>Verknüpfte Fragen</span>
                        </button>
                      </div>
                      <div class="synced-questions" [class.expanded]="showSyncedQuestions(question.id)">
                        <synced-questions [syncedQuestions]="question.syncedQuestions"></synced-questions>
                      </div>
                    </div>
                  }
                }
              }
            </div>
          </div>
        } @else {
          <!-- ---------- LOADING SCREEN --------- -->
          <loading-screen></loading-screen>
        }
      </div>
      @if (questionGroupData) {
        <div class="d-flex w-100 flex-row-reverse justify-content-between my-4">
          @if (currentIndex === availableCollections.length - 1) {
            <button
              mat-flat-button
              class="d-flex justify-content-end"
              color="primary"
              [attr.data-testcafe]="'screenMain-button-finishPrelim'"
              (click)="onFinishPrelim()"
            >
              <span>Vorbefragung beenden</span>
            </button>
          }
          @if (isLastQuestionInSubtopic && currentIndex !== availableCollections.length - 1) {
            <button
              mat-flat-button
              class="d-flex justify-content-end"
              color="primary"
              [attr.data-testcafe]="'screenMain-button-finishTopic'"
              disabled="{{ requestPending || questionGroupLoading }}"
              (click)="nextTopic()"
            >
              <span>Thema abschließen</span>
            </button>
          } @else if (!isLastQuestionInSubtopic && currentIndex !== availableCollections.length - 1) {
            <button
              mat-flat-button
              color="primary"
              [attr.data-testcafe]="'screenMain-button-next'"
              disabled="{{ requestPending || questionGroupLoading }}"
              (click)="doAction('prelim-main', 'next')"
            >
              <span>Weiter</span>
              <kf-icon name="ic_pfeil_rechts_24" />
            </button>
          }
          @if (currentIndex !== 0) {
            <kf-compact-button
              type="secondary"
              class="mr-3"
              color="primary"
              iconName="ic_pfeil_links_24"
              [attr.data-testcafe]="'screenMain-button-prev'"
              [disabled]="requestPending || questionGroupLoading"
              (click)="doAction('prelim-main', 'prev')"
              label="Schritt zurück"
            />
          }
        </div>
      }
    </div>
  } @else {
    <!-- ---------- ERROR SCREEN --------- -->
    <div class="d-flex flex-column flex-grow-1 w-100 align-items-center vr-headline-100">
      Es wurden keine Themen ausgewählt
    </div>
  }
</ng-template>

<!-- ---------- QUESTION TEMPLATE [MULTI] --------- -->
<ng-template #multiQuestion let-question>
  <div class="wrapper-question-template mt-2 mb-1">
    @for (answer of question.answers; track answer; let i = $index) {
      <div class="mb-2 mr-2">
        <vr-answer-multi-select
          #multiSelect
          [id]="answer.id"
          [answerText]="answer.label"
          [tooltipText]="answer.tooltip"
          [preselected]="showPreselectedState(answer.givenAnswers)"
          [notesInput]="showTextFieldData(question, answer.id)"
          [notesInputValue]="getTextFieldData(question, answer.id, 'givenAnswers')"
          [notesPlaceholder]="'Ihre Eingabe'"
          [notesLabel]="answer.textFieldName"
          (click)="sendTempTextRequest()"
          (focused)="[setFocusElement($event, answer.id), sendTempTextRequest()]"
          (valueSelected)="
            [
              setFocusElement(multiSelect.focusElement, answer.id),
              answerSelectMulti(question.answers, question.id, i, $event)
            ]
          "
          (valueSelectedNote)="
            [
              setFocusElement(multiSelect.focusElement, answer.id),
              setTempTextAnswer(question.id, $event, 'multi', question.answers, i)
            ]
          "
        >
        </vr-answer-multi-select>
      </div>
    }
  </div>
</ng-template>

<!-- ---------- QUESTION TEMPLATE [MULTI- /SINGLE-IMAGE] --------- -->
<ng-template #imageQuestion let-question>
  <div class="wrapper-question-template mt-2 mb-1">
    <vr-answer-image-select
      name="multiImage"
      [id]="question.id"
      [data]="imageAnswerData.get(question.id)"
      [multiselect]="question.type === questionEnum.multiImage"
      [deselectable]="true"
      (valuesSelected)="
        answerSelectImage(question.answers, question.id, question.type === questionEnum.multiImage, $event)
      "
      (focused)="setFocusElement($event, question.id)"
    ></vr-answer-image-select>
  </div>
</ng-template>

<!-- ---------- QUESTION TEMPLATE [SINGLE] --------- -->
<ng-template #singleQuestion let-question>
  <mat-radio-group name="single_{{ question.id }}" class="wrapper-question-template">
    @for (answer of question.answers; track answer; let i = $index) {
      <div class="mt-2 mb-1 mr-2">
        <vr-answer-single-select
          #singleSelect
          [id]="answer.id"
          [notesInput]="showTextFieldData(question, answer.id)"
          [notesInputValue]="getTextFieldData(question, answer.id, 'givenAnswers')"
          [notesPlaceholder]="'Ihre Eingabe'"
          [notesLabel]="answer.textFieldName"
          [value]="answer.id"
          [answerText]="answer.label"
          [tooltipText]="answer.tooltip"
          [preselected]="singleAnswerData.get(question.id) === answer.id"
          [selectedValue]="singleAnswerData.get(question.id)"
          [deselectable]="true"
          (click)="sendTempTextRequest()"
          (focused)="[sendTempTextRequest(), setFocusElement($event, answer.id)]"
          (valueSelected)="answerSelectSingle(question.answers, question.id, i, $event)"
          (valueSelectedNote)="setTempTextAnswer(question.id, $event, 'single', question.answers, i)"
        ></vr-answer-single-select>
      </div>
    }
  </mat-radio-group>
</ng-template>

<!-- ---------- QUESTION TEMPLATE [NUMBER] ---------- -->
<ng-template #numberQuestion let-question>
  <vr-answer-number-input
    [defaultNumber]="numericalAnswerData.get(question.id)?.value || 0"
    [stepValue]="question.stepValue || 1"
    [minValue]="numericalAnswerMinMax.get(question.id).min"
    [maxValue]="numericalAnswerMinMax.get(question.id).max"
    [keyboardInput]="true"
    (currentValueChanged)="answerSelectNumeric(question.answers, question.id, $event)"
  >
  </vr-answer-number-input>
</ng-template>

<!-- ---------- QUESTION TEMPLATE [TOGGLE] --------- -->
<ng-template #toggleQuestion let-question>
  @if (!question.label) {
    <div class="mb-2"></div>
  }
  <vr-slide-toggle
    #slidetoggle
    [checked]="singleAnswerData.get(question.id) === question.answers[0].id"
    [label]="slidetoggle['checked'] ? question.answers[0].label : question.answers[1].label"
    (selectChange)="
      slidetoggle['label'] = $event ? question.answers[0].label : question.answers[1].label;
      answerSelectSingle(question.answers, question.id, $event ? 0 : 1, $event)
    "
  >
  </vr-slide-toggle>
</ng-template>

<!-- ---------- QUESTION TEMPLATE [SEGMENT] --------- -->
<ng-template #segmentQuestion let-question>
  <vr-segmented-radio
    name="segment_{{ question.id }}"
    [elements]="segementedAnswerData.get(question.id)"
    (selectChange)="answerSelectSegment(question.answers, question.id, $event)"
    (focused)="setFocusElement($event, question.id)"
  >
  </vr-segmented-radio>
</ng-template>

<!-- ---------- QUESTION TEMPLATE [DATAFIELDGROUP] --------- -->
<ng-template #dataFieldGroupQuestion let-question>
  <data-field-group-question
    [inputValues]="{ itemId: question.id, isQuestionDataField: question.connectedToDataFieldGroups }"
  ></data-field-group-question
></ng-template>

<!-- ---------- QUESTION TEMPLATE [TEXT-MULTILINE] --------- -->
<ng-template #textQuestion let-question>
  <div class="textarea-wrapped w-100">
    <vr-form-textarea
      placeholder="Ihre Eingabe"
      [value]="getTextValue(question)"
      (click)="sendTempTextRequest()"
      (focused)="sendTempTextRequest()"
      (valueChanged)="setTempTextAnswer(question.id, $event, 'text', question.answers, -1)"
    ></vr-form-textarea>
  </div>
</ng-template>

<!-- ---------- QUESTION TEMPLATE [TEXT-SINGLELINE] --------- -->
<ng-template #textField let-question let-answerId="answerId">
  @if (showTextFieldData(question, answerId)) {
    <div class="mt-3">
      <vr-form-text
        [style.width.px]="345"
        [placeholder]="'Ihre Eingabe'"
        [label]="getTextFieldData(question, answerId, 'textFieldName')"
        [mandatory]="false"
        [value]="getTextFieldData(question, answerId, 'givenAnswers')"
        (click)="sendTempTextRequest()"
        (focused)="[sendTempTextRequest(), setFocusElement($event)]"
        (valueChanged)="setTempTextAnswer(question.id, $event, 'text', question.answers, -1)"
      ></vr-form-text>
    </div>
  }
</ng-template>
