import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, UrlSegmentGroup } from '@angular/router';
import { INavigationService } from '@de.fiduciagad.kundenportal/ui-context';
import { AppDimensions } from '@domain/app/dimensions.domain';
import { QuestionGroupResponse } from '@domain/app/question.domain';
import { environment } from '@environment/environment';
import { PortalContextProvider } from '@services/portal-context.provider';
import { BehaviorSubject, ReplaySubject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContextService {
  private _routerSub: Subscription;

  private _assetPath: string;
  private _prepToggleMode: string;

  public context = new BehaviorSubject<string>(undefined);
  public appDimension = new BehaviorSubject<AppDimensions>(undefined);

  public currentUrl: string;
  public previousUrl: string;

  public _currentQuestionGroup = new ReplaySubject<QuestionGroupResponse>();

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private portalContextProvider: PortalContextProvider
  ) {
    this.init();
  }

  init() {
    this._routerSub = this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        if (this.currentUrl && this.currentUrl.includes('(overlay:') === false) {
          this.previousUrl = this.currentUrl;
        }
        this.currentUrl = val.url.length && val.url[0] === '/' ? val.url.slice(1) : val.url;
        this.setContext(this.currentUrl);
      }
    });

    if (environment.platform === 'vp' && !environment.isLocal) {
      const navigationService =
        (this.portalContextProvider.portalContext?.getNavigationService() as INavigationService) || null;
      const portalBasePath = navigationService?.getPortalBasePath() || '';
      const webComponentsPathAtReverseProxy = 'webcomponents';
      const webComponentPath = 'bgzv-frontend-prelim';
      this.assetPath = `${portalBasePath}${webComponentsPathAtReverseProxy}/${webComponentPath}`;
    } else {
      this.assetPath = '';
    }
  }

  public setAppDimensions(dimensionObject: AppDimensions) {
    this.appDimension.next(dimensionObject);
  }

  private setContext(location) {
    this.context.next(location);
  }

  public closeOverlay(outletName: string = 'overlay'): Promise<boolean> {
    const currentUrlTree = this.router.parseUrl(this.router.routerState.snapshot.url);
    const newRootSegment = this.recursivelyClearOutlet(currentUrlTree.root, outletName);
    currentUrlTree.root = newRootSegment;
    return this.router.navigateByUrl(currentUrlTree);
  }
  get currentMainContext() {
    if (this.activeRoute.children.length === 0) {
      return null;
    }

    const routeObject = this.activeRoute.children.find(x => x.outlet === 'primary').snapshot;

    if (routeObject) {
      return `${routeObject.routeConfig.path}`;
    } else {
      return null;
    }
  }

  get currentOverlayContext() {
    const routeObject = this.activeRoute.children.find(x => x.outlet === 'overlay');

    if (routeObject) {
      return routeObject.snapshot.routeConfig.path;
    } else {
      return null;
    }
  }

  get currentMode(): 'prep' | 'main' {
    return this.currentUrl?.includes('prep/') ? 'prep' : 'main';
  }

  get previousContext() {
    return this.previousUrl;
  }

  public get assetPath(): string {
    const a = this._assetPath || '';
    return a ? `${a}/assets` : `assets`;
  }
  public set assetPath(value: string) {
    this._assetPath = value;
  }

  public get prepToggleMode(): string {
    return this._prepToggleMode;
  }
  public set prepToggleMode(value: string) {
    this._prepToggleMode = value;
  }

  public unsubscribe() {
    this._routerSub?.unsubscribe();
  }

  public setCurrentQuestionGroup(qg) {
    this._currentQuestionGroup.next(qg);
  }

  private recursivelyClearOutlet(root: UrlSegmentGroup, outletName: string): UrlSegmentGroup {
    const newChildren = {};
    for (const [childOutlet, child] of Object.entries(root.children)) {
      if (childOutlet !== outletName) {
        const newChild = this.recursivelyClearOutlet(child, outletName);
        newChildren[childOutlet] = newChild;
      }
    }
    return new UrlSegmentGroup(root.segments, newChildren);
  }

  get currentQuestionGroup(): ReplaySubject<QuestionGroupResponse> {
    return this._currentQuestionGroup;
  }
}
