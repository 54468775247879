import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { KfThemeModule } from '@app/kf-theme_module';
import { MaterialModule } from '@app/material_module';
import { DialogConfirmComponent } from '@components/dialog-confirm/dialog-confirm.component';
import { DocumentsUploadComponent } from '@components/documents-upload/documents-upload.component';
import { ItemMenuStructureComponent } from '@components/item-menu-structure/item-menu-structure.component';
import { LoadingScreenComponent } from '@components/loading-screen/loading-screen.component';
import { SyncedQuestionsComponent } from '@components/synced-questions/synced-questions.component';
import { BgzvUtilsModule } from '@utils/utils.module';
import { BgzvLibraryModule } from 'bgzv-frontend-library';
import { AgendaComponent } from './agenda/agenda.component';
import { SliderComponent } from './answeroptions/slider/slider.component';
import { ChatComponent } from './chat/chat.component';
import { ConsultantComponent } from './consultant/consultant.component';
import { CustomerDocumentsComponent } from './customer-documents/customer-documents.component';
import { DataFieldGroupListComponent } from './data-field-group-question/data-field-group-list/data-field-group-list.component';
import { DataFieldGroupPlainComponent } from './data-field-group-question/data-field-group-plain/data-field-group-plain.component';
import { DataFieldGroupQuestionComponent } from './data-field-group-question/data-field-group-question.component';
import { DataFieldGroupTableComponent } from './data-field-group-question/data-field-group-table/data-field-group-table.component';

@NgModule({
  declarations: [
    DialogConfirmComponent,
    ItemMenuStructureComponent,
    SyncedQuestionsComponent,
    LoadingScreenComponent,
    DocumentsUploadComponent,
    ChatComponent,
    AgendaComponent,
    CustomerDocumentsComponent,
    ConsultantComponent,
    DataFieldGroupQuestionComponent,
    DataFieldGroupListComponent,
    DataFieldGroupPlainComponent,
    DataFieldGroupTableComponent,
  ],
  exports: [
    DialogConfirmComponent,
    ItemMenuStructureComponent,
    SyncedQuestionsComponent,
    LoadingScreenComponent,
    DocumentsUploadComponent,
    ChatComponent,
    AgendaComponent,
    CustomerDocumentsComponent,
    ConsultantComponent,
    DataFieldGroupQuestionComponent,
    SliderComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,

    BgzvLibraryModule,
    BgzvUtilsModule,
    KfThemeModule,
    SliderComponent,
  ],
  providers: [],
})
export class BgzvComponentsModule {}
