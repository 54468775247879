import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'thousandsDivider',
})
export class ThousandsDividerPipe implements PipeTransform {
  transform(value: string): string {
    const counter = 3;
    const isNegative = value.charAt(0) === '-';
    isNegative ? (value = value.substring(1)) : (value = value);

    let formattedValue = value;

    const formatValue = (value: string, afterComma: string = '') => {
      let reversed = value.split('').reverse().join('');
      let strL = value.length;
      let i = 0;

      const arr = [];

      do {
        const newstr = reversed.substring(counter * i, counter * i + counter);
        strL = strL - counter;
        i++;
        arr.push(newstr);
      } while (strL > counter);

      const myStr = arr
        .map(item => [...item].reverse().join(''))
        .reverse()
        .join('.');

      const rest = strL > 0 ? value.substring(0, strL) + '.' : '';

      formattedValue = isNegative ? '-' + rest + myStr + afterComma : rest + myStr + afterComma;
    };

    if (formattedValue.length > 6 && formattedValue.search(/\D+/) !== -1) {
      const splitter = formattedValue.match(/\D+/).index;
      let afterComma;

      if (formattedValue.substring(splitter + 1).length < 2) {
        afterComma = ',' + formattedValue.substring(splitter + 1) + '0';
      } else {
        afterComma = ',' + formattedValue.substring(splitter + 1).substring(0, 2);
      }
      formatValue(formattedValue.substring(0, splitter), afterComma);
    }

    if (formattedValue.length >= 5 && formattedValue.search(/\D+/) === -1) {
      formatValue(formattedValue);
    }

    return formattedValue;
  }
}
