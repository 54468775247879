import { AfterViewInit, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationEnd, Router } from '@angular/router';
import { AppDimensions } from '@domain/app/dimensions.domain';
import { RoutingPathMain, RoutingPathOverlay } from '@enums';
import { environment } from '@environment/environment';
import { AuthService } from '@modules/auth';
import { Action, ActionService } from '@services/action-service/action.service';
import { ConfigService } from '@services/config-service/config.service';
import { ContextService } from '@services/context-service/context.service';
import { NetworkService } from '@services/network-service/network.service';
import { Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  private _contextSub: Subscription;
  private _dimensionSub: Subscription;
  private _actionSub: Subscription;
  private _onlineSub: Subscription;
  private _newConfigSub: Subscription;

  public appDimension: AppDimensions;

  public activeLink;

  public overlayActive = false;
  public isOffline = false;
  public firstNavigation = true;
  public routeMain = RoutingPathMain;

  public productDetailInputValues: any;
  public transitionInputValues: any;
  public taskInputValues: any;

  constructor(
    private router: Router,
    private authService: AuthService,
    private actionService: ActionService,
    private contextService: ContextService,
    private configService: ConfigService,
    private networkService: NetworkService,
    private offlineSnackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.router.initialNavigation();
    this.router.events
      .pipe(
        filter(routerEvent => routerEvent instanceof NavigationEnd),
        first(Boolean)
      )
      .subscribe((value: NavigationEnd) => {
        this.activeLink = value.urlAfterRedirects.replace(/^\//, '');
      });

    this._contextSub = this.contextService.context.subscribe(context => {
      if (context !== null && context !== undefined) {
        this.overlayActive = context.includes('overlay:');
      }
    });

    this.contextService.setAppDimensions({ width: window.innerWidth, height: window.innerHeight });
    this._dimensionSub = this.contextService.appDimension.subscribe(dimensionData => {
      this.appDimension = dimensionData;
    });

    if (this.platform === 'aws' && environment.enableOnlinePing) {
      this._onlineSub = this.networkService.onlineDetection().subscribe(networkState => {
        const wasOffline = this.isOffline;
        this.isOffline = !(networkState.hasInternetAccess && networkState.hasNetworkConnection);
        if (this.isOffline && !wasOffline) {
          this.offlineSnackBar.open('Sie sind gerade offline. Bitte stellen Sie wieder eine Internetverbindung her.');
        } else if (!this.isOffline) {
          this.offlineSnackBar.dismiss();
        }
      });
    }

    this._newConfigSub = this.configService.newConfig.subscribe(() => {
      this.handleConfigColors();
    });

    this._actionSub = this.actionService.action.subscribe(action => {
      if (action && action.target === 'main') {
        // if (action.action === 'navigation') {
        //   this.leftSidenavService.toggle();
        // }
        // if (action.action === 'product') {
        //   this.productDetailInputValues = action.options;
        //   this.rightSidenavService.useOnce('product');
        //   this.rightSidenavService.toggle();
        // }
      } else if (action && action.target === 'overlay-main') {
        if (action.action === 'debug') {
          this.router.navigateByUrl(
            this.contextService.currentMainContext + '(overlay:' + RoutingPathOverlay.Debug + ')',
            { state: action.options }
          );
        }
        if (action.action === 'close') {
          this.router.navigateByUrl(this.contextService.currentMainContext);
        }
        if (action.action === 'close-logout') {
          this.router.navigateByUrl(RoutingPathMain.Login, { state: { reason: 'unauthorized' } });
        }
      }
    });

    if (environment.useUserback) {
      const s = document.createElement('script');
      s.async = true;
      s.src = 'https://static.userback.io/widget/v1.js';
      (document.head || document.body).appendChild(s);
    }
  }

  ngOnDestroy(): void {
    this._contextSub?.unsubscribe();
    this._actionSub?.unsubscribe();
    this._onlineSub?.unsubscribe();
    this._newConfigSub?.unsubscribe();
    this._dimensionSub?.unsubscribe();
  }

  ngAfterViewInit() {
    window.addEventListener('message', event => {
      if (event.origin === environment.consultationOrigin) {
        this.authService.loginFromMessage(event.data);
      }
    });

    window.opener?.postMessage({ preliminaryLoaded: true }, environment.consultationOrigin);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const newDimensions = {} as AppDimensions;
    newDimensions.width = event.target.innerWidth;
    newDimensions.height = event.target.innerHeight;
    this.contextService.setAppDimensions(newDimensions);
  }

  doAction(target: string = '', action: string = '', options?: any) {
    const data = { target: target, action: action } as Action;
    if (options) {
      data.options = options;
    }
    this.actionService.setAction(data);
  }

  private handleConfigColors() {
    let primary;
    let secondary;

    const hexToRgb = hex =>
      hex
        .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b)
        .substring(1)
        .match(/.{2}/g)
        .map(x => parseInt(x, 16));

    if (environment.platform === 'aws') {
      primary = this.configService.getSettingsValue('primaryColorFrontend');
      secondary = this.configService.getSettingsValue('secondaryColorFrontend');
    } else if (environment.platform === 'vp') {
      const themeColors = document.querySelector('#theme-colors') as HTMLStyleElement;
      const rules = themeColors.sheet.cssRules[0] as CSSStyleRule;
      if (rules?.style) {
        primary = rules.style.getPropertyValue('--color-primary-500').trim();
        secondary = rules.style.getPropertyValue('--color-accent-500').trim();
        themeColors.remove();
      } else if (environment.platform === 'vp' && !!this.configService.getSettingsValue('preventKFThemeOverride')) {
        primary = this.configService.getSettingsValue('primaryColorFrontend');
        secondary = this.configService.getSettingsValue('secondaryColorFrontend');
      } else {
        return;
      }
    }

    if (primary) {
      document.documentElement.style.setProperty('--color-brand-primary', primary);
      document.documentElement.style.setProperty('--color-brand-primary-rgb', hexToRgb(primary).join());
    }
    if (secondary) {
      document.documentElement.style.setProperty('--color-brand-secondary', secondary);
      document.documentElement.style.setProperty('--color-brand-secondary-rgb', hexToRgb(secondary).join());
    }
  }

  get platform(): 'aws' | 'vp' {
    return environment.platform as 'aws' | 'vp';
  }
}
