@for (dataField of dataFieldGroup.dataFields; track dataField) {
  <p class="dataField">
    <strong class="dataField__key">{{ dataField.name }}: </strong>
    @if (dataField.type === dataFieldTypeEnum.price) {
      <span
        class="dataField__val"
        [attr.data-testcafe]="getTestcafeId(dataField.name)"
        handleENumbers
        markNegativePrice
      >
        {{ getValueMethod(dataField) | thousandsDivider }}
      </span>
    } @else {
      <span
        class="dataField__val"
        [attr.data-testcafe]="getTestcafeId(dataField.name)"
        handleENumbers
        markNegativePrice
      >
        {{ getValueMethod(dataField) }}
      </span>
    }
  </p>
}
