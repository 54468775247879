import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogConfirmComponent, DialogConfirmData } from '@components/dialog-confirm/dialog-confirm.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private dialog: MatDialog) {}

  openDialogConfirm(data: DialogConfirmData) {
    const dialogOptions = new MatDialogConfig();
    dialogOptions.autoFocus = false;
    dialogOptions.hasBackdrop = true;
    dialogOptions.data = data;

    return this.dialog.open(DialogConfirmComponent, dialogOptions);
  }
}
