<div class="d-flex flex-column mb-5">
  <h3 class="vr-headline-200 mb-4">{{ getTopicText() }}</h3>
  @if (currentConsultation) {
    <ng-container>
      <div class="d-flex mb-2">
        <div class="vr-icon-calendar-24" aria-hidden="true"></div>
        <div class="ml-2">
          <h4 class="vr-text-label mb-0">Datum</h4>
          <p>{{ getTopicDate() }}</p>
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="vr-icon-clock-24" aria-hidden="true"></div>
        <div class="ml-2">
          <h4 class="vr-text-label mb-0">Uhrzeit</h4>
          <p>{{ getTopicTime() }}</p>
        </div>
      </div>
      <div class="d-flex">
        <div class="vr-icon-note-24"></div>
        <h4 class="vr-text-label ml-2 mb-0">Themen</h4>
      </div>
      @if (topicContent.length > 0) {
        <ol class="list-unstyled topic-list">
          @for (topic of topicContent; track topic) {
            <li class="topic-list-item vr-text-regular">
              <span class="d-inline-block">
                <span class="d-flex align-items-center topic-list-item-text">{{ topic }}</span>
              </span>
            </li>
          }
        </ol>
      } @else {
        <p>Keine Themen ausgewählt.</p>
      }
      <div #ref>
        <button
          mat-flat-button
          class="kf-btn-flexible"
          color="primary"
          [attr.data-testcafe]="'screenStart-button-beginPrelim'"
          [disabled]="!currentConsultation || noTopicsAvailable"
          (click)="!currentConsultation ? null : onStartPrelim($event)"
        >
          <span>Vorbefragung öffnen</span>
          <kf-icon name="ic_pfeil_rechts_24" />
        </button>
      </div>
    </ng-container>
  }
</div>
