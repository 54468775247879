@if (currentConsultantData) {
  <div class="w-100 p-4 d-flex justify-content-between border-bottom consultant-header">
    <div>
      <h3 class="vr-headline-100 mb-1">Ihr Berater</h3>
      <div class="d-flex">
        <div class="vr-icon-person-24" aria-hidden="true"></div>
        <span class="ml-2">{{ currentConsultantData?.firstName }} {{ currentConsultantData?.lastName }}</span>
      </div>
    </div>
    <div>
      @if (currentConsultantData.mail) {
        <div class="d-flex">
          <div class="vr-icon-mail-24" aria-hidden="true"></div>
          <a
            #ref
            href="mailto:{{ currentConsultantData?.mail }}"
            [attr.data-testcafe]="'startScreen-href-mailToConsultant'"
            class="ml-2 color-current"
            >{{ currentConsultantData.mail }}</a
          >
        </div>
      }
      @if (currentConsultantData.phone) {
        <div class="d-flex">
          <div class="vr-icon-phone-24" aria-hidden="true"></div>
          <div class="ml-2">{{ currentConsultantData.phone }}</div>
        </div>
      }
    </div>
  </div>
}
