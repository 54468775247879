import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from '@app/material_module';
import { BgzvComponentsModule } from '@components/components.module';
import { MarginalsHeaderComponent } from '@marginals/marginals-header/marginals-header.component';
import { BgzvUtilsModule } from '@utils/utils.module';
import { BgzvLibraryModule } from 'bgzv-frontend-library';

@NgModule({
  declarations: [MarginalsHeaderComponent],
  exports: [MarginalsHeaderComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,

    BgzvLibraryModule,
    BgzvComponentsModule,
    BgzvUtilsModule,
  ],
  providers: [],
})
export class BgzvMarginalsModule {}
