import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ActionService } from '@services/action-service/action.service';
import { CacheService } from '@services/cache-service/cache.service';
import { ContextService } from '@services/context-service/context.service';
import { DialogService } from '@services/dialog-service/dialog.service';
import { LoadingService } from '@services/loading-service/loading.service';
import { QueryService } from '@services/query-service/query.service';
import { LeftSidenavService, RightSidenavService } from '@services/side-service/sidenav.service';
import { StorageService } from '@services/storage-service/storage.service';
import { ClientService } from './client-service/client.service';

@NgModule({
  exports: [],
  imports: [HttpClientModule],
  providers: [
    ActionService,
    CacheService,
    ContextService,
    DialogService,
    ClientService,
    LoadingService,
    LeftSidenavService,
    RightSidenavService,
    QueryService,
    StorageService,
  ],
})
export class BgzvServicesModule {}
