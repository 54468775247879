import { Component, Input } from '@angular/core';
import { getValue } from '@components/data-field-group-question/data-field-group-question.component';
import { DataFieldGroupQuestion } from '@domain/app/data-field-group-question';
import { DataFieldTypeEnum } from '@enums';
import DataFieldGroupPlain = DataFieldGroupQuestion.DataFieldGroupPlain;

@Component({
  selector: 'data-field-group-plain',
  templateUrl: './data-field-group-plain.component.html',
  styleUrls: ['./data-field-group-plain.component.scss'],
})
export class DataFieldGroupPlainComponent {
  @Input() dataFieldGroup: DataFieldGroupPlain;

  public getValueMethod = getValue;
  public dataFieldTypeEnum = DataFieldTypeEnum;

  public getTestcafeId(name: string) {
    return `dataField-plain-${name?.replace(/ /g, '')}`;
  }
}
