import { NgModule } from '@angular/core';
import { DebugIdDirective } from './directives/debug-id.directive';
import { ReplacementPipe } from './pipes/replacement.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { ThousandsDividerPipe } from './pipes/thousandsDivider.pipe';

@NgModule({
  declarations: [DebugIdDirective, ReplacementPipe, SafeHtmlPipe, ThousandsDividerPipe],
  imports: [],
  exports: [DebugIdDirective, ReplacementPipe, SafeHtmlPipe, ThousandsDividerPipe],
  providers: [DebugIdDirective, ReplacementPipe, SafeHtmlPipe, ThousandsDividerPipe],
})
export class BgzvUtilsModule {}
