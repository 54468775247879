export enum RoutingPathMain {
  Default = '',
  Login = 'login',
  Start = 'start',
  Main = 'main',
  Done = 'done',
}

export enum RoutingPathOverlay {
  Debug = 'debug',
}

export enum ConsultationStatusEnum {
  created = 'CREATED',
  inPreparation = 'IN_PREPARATION',
  preparationSent = 'PRELIMINARY_SEND',
  preparationDone = 'PRELIMINARY_FINISHED',
  mainConsultation = 'MAIN_CONSULTATION',
  checkoutDone = 'CHECKOUT_FINISHED',
  archived = 'ARCHIVED',
}

export enum TopicStatusEnum {
  done = 'COMPLETE',
  inProgress = 'IN_PROGRESS',
  open = 'OPEN',
}

export enum TopicPositionEnum {
  logo = 'LOGO',
  bank = 'BANK',
  topLeft = 'TOP_LEFT',
  topCenter = 'TOP_CENTER',
  topRight = 'TOP_RIGHT',
  centerLeft = 'CENTER_LEFT',
  center = 'CENTER_CENTER',
  centerRight = 'CENTER_RIGHT',
  downLeft = 'DOWN_LEFT',
  downCenter = 'DOWN_CENTER',
  downRight = 'DOWN_RIGHT',
}

export enum MediaTypeEnum {
  image = 'IMAGE',
  pdf = 'PDF',
  video = 'VIDEO',
  link = 'LINK',
  docImage = 'DOC_IMAGE',
  logo = 'LOGO_IMAGE',
}

export enum QuestionImageBehavior {
  small = 'SMALL',
  large = 'LARGE',
  fixedSmall = 'SMALL_FIXED',
  fixedLarge = 'LARGE_FIXED',
}

export enum QuestionEnum {
  single = 'SINGLE',
  multi = 'MULTI',
  text = 'TEXT',
  singleImage = 'SINGLEIMAGE',
  multiImage = 'MULTIIMAGE',
  number = 'NUMBER',
  slider = 'SLIDER',
  expSlider = 'SLIDERDECLOG',
  toggle = 'TOGGLE',
  segments = 'SEGMENTRADIO',
  connectedSlider = 'CONNECTEDSLIDERS',
  date = 'DATE',
  variantCalculator = 'VARIANT_CALCULATOR',
  dataFieldGroup = 'DATA_FIELD_GROUP',
}

export enum QuestionTemplateEnum {
  default = 'GENERAL',
  products = 'PRODUCT_CONFIGURATION',
}

export enum GivenAnswerEnum {
  customer = 'CUSTOMER',
  consultant = 'CONSULTANT',
  consultation = 'CONSULTATION',
  history = 'HISTORY',
  none = 'NONE',
}

export enum PaymentInterval {
  fixed = 'FIXED',
  annually = 'ANNUALLY',
  monthly = 'MONTHLY',
  once = 'ONCE',
  expense = 'ONCE_TO_EXPENDITURE',
  customOffer = 'CUSTOM_OFFER',
}

export enum VatEnum {
  vat0 = 'PERCENT_0',
  vat7 = 'PERCENT_7',
  vat19 = 'PERCENT_19',
  vat19optional = 'PERCENT_19_OPTIONAL',
  vatSaving = 'SAVINGS_RATE',
}

export enum BenefitTypeEnum {
  time = 'TIME',
  money = 'MONEY',
  process = 'PROCESS',
  revenue = 'REVENUE',
  advantage = 'ADVANTAGES',
}

export enum ProductTemplateEnum {
  generic = 'GENERIC',
}

export enum RecommendationTypeEnum {
  product = 'PRODUCT',
  main = 'MAIN_PRODUCT',
  variant = 'VARIANT_PRODUCT',
  additional = 'ADDITIONAL_PRODUCT',
  task = 'TASK',
  transisiton = 'TRANSITION',
}

export enum DataFieldTypeEnum {
  text = 'TEXT',
  longText = 'LONG_TEXT',
  number = 'NUMBER',
  date = 'DATE',
  dropdown = 'DROPDOWN',
  radio = 'RADIO_GROUP',
  checkbox = 'CHECKBOX',
  images = 'FILES_IMAGES',
  hidden = 'HIDDEN',
  price = 'PRICE',
}

export enum DataFieldElementTypeEnum {
  general = 'general',
  products = 'products',
  transitions = 'transitions',
  tasks = 'tasks',
  profile = 'profile',
}

export enum DataFieldTemplateEnum {
  temp1 = 'TEMP1',
  temp2 = 'TEMP2',
}

export enum DataFieldCategoryEnum {
  base = 'BASE',
  business = 'BUSINESS',
  account = 'ACCOUNT',
}

export enum PriorityEnum {
  low = 'LOW',
  normal = 'NORMAL',
  high = 'HIGH',
}

export enum ProductTypes {
  products = 'PRODUCTS',
  tasks = 'TASKS',
  transitions = 'TRANSITIONS',
}

export enum ClientTabTypes {
  myClients = 0,
  allClients = 1,
}

export enum CustomerImportRequestTypeEnum {
  default = 'DEFAULT',
  all = 'ALL',
  vp = 'VP_VKS_IMPORT',
  mmm = 'MMM_IMPORT',
  zvr = 'ZVR_IMPORT',
}

export enum DocumentUploadEnum {
  image = 'IMAGE',
  pdf = 'PDF',
}

export enum CheckoutDataFieldStatusEnum {
  none = 'NONE',
  notComplete = 'NOT_COMPLETE',
  mandatoryDone = 'MANDATORY_DONE',
  complete = 'COMPLETE',
}

export enum ContactTypeEnum {
  customer = 'CUSTOMER',
  consultant = 'CONSULTANT',
  expert = 'EXPERT',
}
