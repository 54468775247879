import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginForm, PasswordChangeForm } from '@domain/app/forms.domain';
import { RoutingPathMain } from '@enums';
import { AuthService } from '@modules/auth';
import { ContextService } from '@services/context-service/context.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'screen-login',
  templateUrl: './screen-login.component.html',
  styleUrls: ['./screen-login.component.scss'],
})
export class ScreenLoginComponent implements OnInit, OnDestroy {
  private _authSub: Subscription;
  private _authErrorSub: Subscription;

  public loginMessage = null;

  loginForm: FormGroup<LoginForm>;
  passwordChangeForm: FormGroup<PasswordChangeForm>;

  loading = false;
  submitted = false;
  showPassword: boolean;
  error: string;

  // temp
  authState: string;
  authObject: any;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private contextService: ContextService
  ) {
    if (this.router.getCurrentNavigation().extras.state) {
      const stateObj = this.router.getCurrentNavigation().extras.state;
      // do stuff...
      this.loginMessage = null;
      if (stateObj.reason) {
        if (stateObj.reason === 'logout') {
          this.loginMessage = 'Sie haben sich erfolgreich ausgeloggt.';
        }
        if (stateObj.reason === 'unauthorized') {
          this.loginMessage = 'Die Session ist abgelaufen.';
        }
      }
    }
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });

    this.passwordChangeForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
    });

    this._authSub = this.authService.currentAuthState.subscribe(authState => {
      if (!authState) {
        return;
      }

      if (this.authService.authState === 'requireNewPassword') {
        console.log('NEW PASSWORD TO BE SET');
        this.authState = this.authService.authState;
        this.submitted = false;
        this.loading = false;
        this.error = '';
      }
      if (this.authService.authState === 'signedIn') {
        this.error = '';
        this.loading = false;

        this.router.navigate([RoutingPathMain.Start]);
      }
    });

    this._authErrorSub = this.authService.currentErrorState.subscribe(error => {
      if (!!error) {
        this.handleErrorState(error);
      }
    });
  }

  ngOnDestroy(): void {
    if (this._authSub) {
      this._authSub.unsubscribe();
    }
    if (this._authErrorSub) {
      this._authErrorSub.unsubscribe();
    }
  }

  handleLogin() {
    this.submitted = true;
    this.loading = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      this.loading = false;
      return;
    }
    this.authService
      .login(this.formUsername.value, this.formPassword.value)
      .then(x => {})
      .catch(e => {});
  }

  handlePasswordChange() {
    this.submitted = true;
    this.loading = true;

    if (this.passwordChangeForm.invalid) {
      this.loading = false;
      return;
    }

    this.authService
      .login(this.formUsername.value, this.formPassword.value, this.formChangePassword.value)
      .then(() => {
        this.authState = '';
        this.error = '';
        this.submitted = false;
        this.loading = false;

        this.formPassword.reset();
      })
      .catch(e => {});
  }

  handleErrorState(err) {
    this.loading = false;
    if (!err || err === true || err?.code === undefined) {
      this.error = '';
      return false;
    }

    if (err.code && err.code === 'NotAuthorizedException') {
      this.error = 'Falsches Passwort';
    }
    if (err.code && err.code === 'UserNotFoundException') {
      this.error = 'Nutzer exisitert nicht';
    }
    if (err.code && err.code === 'InvalidPasswordException') {
      this.error = 'Passwort entspricht nicht den Anforderungen';
    }

    return true;
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  get formControls() {
    return this.loginForm.controls;
  }
  get formPassword() {
    return this.formControls.password;
  }
  get formUsername() {
    return this.formControls.username;
  }

  get formChangeControls() {
    return this.passwordChangeForm.controls;
  }
  get formChangePassword() {
    return this.formChangeControls.password;
  }

  get assetPath(): string {
    return this.contextService.assetPath;
  }
}
