import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RoutingPathMain } from '@enums';
import { AuthService } from '@modules/auth';
import { Action, ActionService } from '@services/action-service/action.service';
import { color } from 'bgzv-frontend-library';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-screen-done',
  templateUrl: './screen-done.component.html',
  styleUrls: ['./screen-done.component.scss'],
})
export class ScreenDoneComponent implements OnInit, OnDestroy {
  private _actionSub: Subscription;
  private _dialogSub: Subscription;
  private _dialogRef = null;

  public readonly color = color;

  constructor(
    private router: Router,
    private authService: AuthService,
    private actionService: ActionService
  ) {}

  ngOnInit(): void {
    this._actionSub = this.actionService.action.subscribe(action => {
      if (action && action.target === 'prelim-done') {
      }
    });

    // TODO: Get Consultant and Consultation Info
  }

  ngOnDestroy(): void {
    this._actionSub?.unsubscribe();
    this._dialogSub?.unsubscribe();
  }

  doAction(target: string = '', action: string = '', options?: any) {
    const data = { target: target, action: action } as Action;
    if (options) {
      data.options = options;
    }
    this.actionService.setAction(data);
  }

  onFinishPrelim(ev: Event) {
    this.authService.logout();
    this.router.navigate([RoutingPathMain.Login]);
  }
}
