import { Component, Input } from '@angular/core';
import { getValue } from '@components/data-field-group-question/data-field-group-question.component';
import { DataFieldGroupQuestion } from '@domain/app/data-field-group-question';
import { DataFieldTypeEnum } from '@enums';
import DataFieldGroupList = DataFieldGroupQuestion.DataFieldGroupList;

@Component({
  selector: 'data-field-group-list',
  templateUrl: './data-field-group-list.component.html',
  styleUrls: ['./data-field-group-list.component.scss'],
})
export class DataFieldGroupListComponent {
  @Input() dataFieldGroup: DataFieldGroupList;

  public getValueMethod = getValue;
  public dataFieldTypeEnum = DataFieldTypeEnum;
}
