@if (syncedQuestions && syncedQuestions.length > 0) {
  <div class="sync-question-container">
    @for (syncedQuestion of syncedQuestions; track syncedQuestion) {
      <div>
        {{ splitSyncedQuestionDisplayName(syncedQuestion.questionDisplayName) }}
        @for (answer of syncedQuestion.answers; track answer) {
          <div class="ml-3 mt-2">
            <ng-container *ngTemplateOutlet="answerType; context: { $implicit: answer.answeredBy }"></ng-container>
            <ul>
              @for (answerValue of answer.values; track answerValue) {
                <li>
                  {{ displayAnswerValue(answerValue) }}
                </li>
              }
            </ul>
          </div>
        }
      </div>
    }
  </div>
}

<ng-template #answerType let-answeredBy>
  <div class="d-flex align-items-center">
    <div
      class="mr-2"
      [ngClass]="isAnsweredByMainConsultation(answeredBy) ? 'vr-icon-users' : 'vr-icon-user'"
      [style.backgroundColor]="
        isAnsweredByMainConsultation(answeredBy)
          ? color.find(color.name.BrandPrimary)
          : color.find(color.name.Neutral700)
      "
      style="height: 0.75rem; width: 0.75rem"
    ></div>
    <div
      class="vr-text-small"
      [ngClass]="isAnsweredByMainConsultation(answeredBy) ? 'vr-color-brand-primary' : 'vr-color-neutral-700'"
    >
      {{ getAnswerTypeName(answeredBy) }}
    </div>
  </div>
</ng-template>
