<header class="d-flex bg-white content-width position-relative prelim-header">
  @if (currentEnvironment === 'vp') {
    <kf-compact-button
      class="mt-2 mr-2 cursor-pointer"
      color="primary"
      iconName="ic_pfeil_links_32"
      (click)="backToAgenda()"
      label="Zurück zur Terminübersicht"
    />
  }
  <h2 class="vr-headline-300 font-weight-bold vr-color-brand-primary-alternative">Vorbefragung für Ihre Beratung</h2>
  <p class="sr-only">
    Unsere professionelle Zahlungsverkehrsberatung bietet eine individuelle Analyse der Ist-Situation in Ihrem
    Unternehmen sowie Lösungen für den optimalen Zahlungsverkehr in der Zukunft. So schaffen Sie mehr Sicherheit und
    heben verborgene Ressourcen.
  </p>
</header>

<main
  class="bg-white d-flex flex-wrap {{
    !loading ? 'justify-content-between' : 'justify-content-center contents-loading'
  }} content-width"
>
  @if (!loading) {
    @if (!loading && (!consultationData || consultationData.length === 0)) {
      <ng-container *ngTemplateOutlet="noPrelim"></ng-container>
    }
    @if (consultationData && consultationData.length !== 0) {
      <section class="d-flex flex-column box-padding prelim-card marginals-start-section">
        <ng-container *ngTemplateOutlet="agenda"></ng-container>
        <ng-container *ngTemplateOutlet="documents"></ng-container>
      </section>
      <section class="d-flex flex-column prelim-card vh-100 overflow-hidden start-section">
        <ng-container *ngTemplateOutlet="consultantAndMessaging"></ng-container>
      </section>
    }
  } @else {
    <loading-screen></loading-screen>
  }
</main>

<!--templates-->
<ng-template #agenda>
  <agenda [currentConsultation]="currentConsultation"></agenda>
</ng-template>

<ng-template #documents>
  <customer-documents></customer-documents>
</ng-template>

<ng-template #consultantAndMessaging>
  <consultant [data]="consultationData"></consultant>
  <chat chatContext="consultation" class="h-100 d-flex flex-column overflow-hidden"></chat>
</ng-template>

<!-- ---------- LOADING SCREEN --------- -->

<ng-template #noPrelim>
  <div class="font-weight-bold">
    <h3 class="vr-headline-200 mb-4">Keine Vorbefragung verfügbar</h3>
  </div>
</ng-template>
