import { LocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { environment } from '@environment/environment';
import { screenRoutes } from '@screens/screens.module';
import { CustomLocationStrategy } from './custom-location-strategy';

const routes: Routes = [
  ...screenRoutes,
  { path: '**', redirectTo: environment.platform === 'aws' ? 'login' : 'start' },
];

const options: ExtraOptions = { useHash: true };

@NgModule({
  imports: [RouterModule.forRoot(routes, options)],
  providers: [{ provide: LocationStrategy, useClass: CustomLocationStrategy }],
  exports: [RouterModule],
})
export class AppRoutingModule {}
