import { Component, Input } from '@angular/core';
import { getValue } from '@components/data-field-group-question/data-field-group-question.component';
import { DataFieldGroupQuestion } from '@domain/app/data-field-group-question';
import { DataFieldTypeEnum } from '@enums';
import DataFieldGroupTable = DataFieldGroupQuestion.DataFieldGroupTable;

@Component({
  selector: 'data-field-group-table',
  templateUrl: './data-field-group-table.component.html',
  styleUrls: ['./data-field-group-table.component.scss'],
})
export class DataFieldGroupTableComponent {
  @Input() dataFieldGroup: DataFieldGroupTable;

  public getValueMethod = getValue;
  public dataFieldTypeEnum = DataFieldTypeEnum;

  public getTestcafe(dataField: any, dataFieldGroup: any, idx: number) {
    return `'dataField-table-' +
    ${dataField.name} +
    '-' +
    ${dataFieldGroup.groupName} +
    '-' +
    ${this.getValueMethod(dataField, idx)} +
    '-' +
    ${[idx]}`;
  }

  get headerRow(): string[] {
    return this.dataFieldGroup.dataFields.map(dataField => dataField.name);
  }

  get dataRowsIndexes(): number[] {
    // TODO: change this to new data-format
    const count =
      this.dataFieldGroup.dataFields.length === 0
        ? 0
        : Math.max(...this.dataFieldGroup.dataFields.map(dataField => dataField.values.length));
    return [...Array(count).keys()];
  }
}
