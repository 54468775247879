@if (hasPrelimSelectedItems(data.subtopics) && displayType === 'topic-list') {
  <ol class="list-unstyled topic-list">
    @for (groupItem of getQuestionGroups(data.subtopics); track groupItem; let isLast = $last) {
      <ng-template *ngTemplateOutlet="questionGroupItemsAsList; context: { $implicit: groupItem }"></ng-template>
    }
  </ol>
}

@if (hasPrelimSelectedItems(data.subtopics) && displayType !== 'topic-list') {
  <mat-expansion-panel
    #outerPanel
    class="w-100 mat-elevation-z0"
    [expanded]="currentQuestionGroup && currentQuestionGroup?.topicId === data.id"
    [disabled]="options.isDisabled"
  >
    <mat-expansion-panel-header
      [collapsedHeight]="options.headerHeight"
      [expandedHeight]="options.headerHeight"
      tabindex="0"
      class="d-flex align-items-center flex-grow-1 items m-1"
    >
      <div
        class="d-flex align-items-center pl-3 text-truncate vr-text-regular {{
          currentQuestionGroup?.topicId === data.id ? 'font-weight-bold' : null
        }}"
        style="width: 90%"
      >
        {{ data.name }}
      </div>
    </mat-expansion-panel-header>
    <!-- ------ QUESTIONGROUP ITEMS ------ -->
    <ng-container>
      @for (groupItem of getQuestionGroups(data.subtopics); track groupItem; let isLast = $last) {
        <ng-template *ngTemplateOutlet="questionGroupItems; context: { $implicit: groupItem }"></ng-template>
      }
    </ng-container>
    <!-- --------------------- INNER ACCORDION END --------------------- -->
  </mat-expansion-panel>
  <ng-container><hr /></ng-container>
}
<!-- ------------------------ INNER ACCORDION TEMPLATE ------------------------ -->
<ng-template #innerAccordion let-innerItems let-displayType="displayType">
  <mat-accordion [multi]="true" [displayMode]="'flat'">
    @for (innerItem of innerItems; track innerItem) {
      <div>
        @if (currentQuestionGroup && innerItem.showInPreliminary && hasPrelimSelectedItems(innerItem.questionGroups)) {
          <ng-container><hr /></ng-container>
          <!-- ------ SUBTOPIC ITEMS ------ -->
          <mat-expansion-panel
            #innerPanel
            class="mat-elevation-z0 ml-4 inner-accordion"
            [hideToggle]="false"
            [expanded]="currentQuestionGroup?.subtopicId === innerItem.id"
          >
            <mat-expansion-panel-header
              [collapsedHeight]="options.headerHeight"
              [expandedHeight]="options.headerHeight"
              class="d-flex align-items-center flex-grow-1"
              [style.paddingLeft]="options.mainMarginLeft"
            >
              <div class="d-flex align-items-center">
                @if (displayType.includes('select')) {
                  <div style="width: 1.75rem"></div>
                }
                @if (displayType === 'progress') {
                  <div class="pl-1" style="width: 1.75rem; min-width: 1.75rem">
                    @if (innerItem.status !== status.open) {
                      <ng-template
                        *ngTemplateOutlet="innerItem.status === status.done ? iconDone : iconProgress"
                      ></ng-template>
                    }
                  </div>
                }
                @if (displayType === 'check') {
                  <div class="pl-1" style="width: 1.75rem">
                    <div
                      class="vr-icon-check"
                      style="width: 16px; height: 16px"
                      [style.backgroundColor]="
                        innerItem.status === status.done
                          ? color.find(color.name.BrandPrimary)
                          : color.find(color.name.Neutral500)
                      "
                    ></div>
                  </div>
                }
                <div
                  class="vr-text-regular {{
                    currentQuestionGroup?.subtopicId === innerItem.id ? 'font-weight-bold' : null
                  }}"
                  [style.color]="color.find(color.name.Neutral900)"
                >
                  {{ innerItem.name }} innerItem
                </div>
              </div>
            </mat-expansion-panel-header>
            <!-- ------ QUESTIONGROUP ITEMS ------ -->
            <ng-container>
              @for (groupItem of innerItem.questionGroups; track groupItem; let isLast = $last) {
                <ng-template *ngTemplateOutlet="questionGroupItems; context: { $implicit: groupItem }"></ng-template>
              }
            </ng-container>
          </mat-expansion-panel>
        }
      </div>
    }
    <div class="m-n4"></div>
  </mat-accordion>
</ng-template>

<ng-template #questionGroupItemsAsList let-groupItem>
  @if (displayType === 'topic-list') {
    @if (currentQuestionGroup && groupItem.showInPreliminary) {
      <li
        class="topic-list-item vr-text-regular {{
          groupItem.status !== status.inProgress && currentQuestionGroup?.collectionId !== groupItem.id
            ? null
            : 'vr-color-brand-primary'
        }}"
      >
        <button
          #refHeader
          (click)="handleQuestionGroupSelect(false, groupItem)"
          (keyup)="click($event, refHeader)"
          tabindex="0"
          class="button-unstyled topic-list-item-trigger"
        >
          <span
            #refItem
            class="topic-list-item-text d-flex align-items-center {{
              groupItem.status !== status.inProgress && currentQuestionGroup?.collectionId !== groupItem.id
                ? null
                : 'vr-color-brand-primary topic-finished'
            }}"
            (keyup)="click($event, refItem)"
          >
            {{ groupItem.name }}
          </span>
        </button>
      </li>
    }
  }
</ng-template>

<ng-template #questionGroupItems let-groupItem>
  @if (currentQuestionGroup && groupItem.showInPreliminary) {
    <div>
      <hr />
      <a
        #refHeader
        class="d-flex align-items-center items m-1"
        [style.height]="'3rem'"
        [style.background]="color.find(color.name.White)"
        (click)="handleQuestionGroupSelect(false, groupItem)"
        [style.marginLeft]="options.groupIndent"
        (keyup)="click($event, refHeader)"
        tabindex="0"
      >
        <a
          #refItem
          class="vr-text-regular question-group-items ml-5 d-flex flex-column align-items-center {{
            groupItem.status !== status.inProgress ? ' vr-color-neutral-700' : 'vr-color-brand-primary'
          }} {{ currentQuestionGroup?.collectionId === groupItem.id ? 'font-weight-bold' : null }}"
          [style.color]="
            currentQuestionGroup?.collectionId === groupItem.id
              ? color.find(color.name.BrandPrimary)
              : color.find(color.name.Neutral900)
          "
          (keyup)="click($event, refItem)"
        >
          {{ groupItem.name }}
        </a>
      </a>
    </div>
  }
</ng-template>

<ng-template #iconProgress>
  <div class="circle progress"></div>
</ng-template>
<ng-template #iconDone>
  <div class="circle done"></div>
</ng-template>
