import { Directive, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ConfigService } from '@services/config-service/config.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[debugId]',
})
export class DebugIdDirective implements OnInit, OnChanges {
  @Input('debugId') id: number | string;

  constructor(
    private el: ElementRef,
    private configService: ConfigService
  ) {}

  ngOnInit() {
    if (this.configService.isDebugMode && this.id) {
      this.el.nativeElement.innerHTML = `[${this.id}] ${this.el.nativeElement.innerHTML}` || `[${this.id}]`;
    }
  }

  ngOnChanges(event: SimpleChanges) {
    if (this.configService.isDebugMode && this.id) {
      if (event.id.previousValue !== undefined) {
        this.el.nativeElement.innerHTML = `[${this.id}] ${this.el.nativeElement.innerHTML}` || `[${this.id}]`;
      }
    }
  }
}
