<div class="no-select w-100">
  <span class="sr-only" id="uploadFieldDescr">Eingabefeld für das Hochladen von Dokumenten</span>
  <input
    #input
    type="file"
    (click)="resetPath($event)"
    (change)="onFileSelect($event)"
    [accept]="fileAccept"
    multiple
    aria-labelledby="uploadFieldDescr"
  />

  <button
    mat-stroked-button
    class="kf-btn-flexible"
    color="primary"
    [attr.data-testcafe]="'screen-start-button-uploadDocument'"
    (click)="input.click()"
  >
    <kf-icon name="ic_hochladen_24" />
    <span>Dokument hochladen</span>
  </button>
  @if (errorMessage) {
    <div class="d-flex mt-4 vr-text-small vr-color-error w-75">
      {{ errorMessage }}
    </div>
  }
</div>
